import React, { useReducer, useState, useMemo, useEffect, useCallback } from 'react';
import { GoogleMap, MarkerClusterer } from '@react-google-maps/api';
import debounce from 'lodash/debounce';

import styles from './MarketSearchMap.module.scss';

import reducer from '../../../reducers/UndoRedoReducer';

import useDrawingManager from '../../../hooks/useDrawingManager';
import useMarkers from '../../../hooks/useMarkers';

import Markers from './Markers';
import UndoRedoControl from './UndoRedoControl';
import MarketSearchDrawer from './MarketSearchDrawer';
import { formatNumberToDollars } from '../../../utils/numberFormat';
import LoadingOverlay from './LoadingOverlay';

import mapStyles from '../../../utils/mapStyles';

const searchDelay = 1200; // Time in ms

const MapSearchWithBounds = ({
  drawerContainerRef,
  properties,
  setIsFiltersDrawerOpen,
  isMapDrawerOpen,
  handleClickMapDrawerClose,
  params,
  onMapMove,
  isLoading,
  filters
}) => {
  const [bounds, setBounds] = useState({
    ne: {},
    sw: {},
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [center, setCenter] = useState({ lat: 32.779167, lng: -96.808891 }); // Default center
  const [shouldUpdateOnMove, setShouldUpdateOnMove] = useState(true);
  const [state, dispatch] = useReducer(reducer, {
    past: [],
    now: [],
    future: [],
  });
  const drawingManager = useDrawingManager();
  const [mapRef, setMapRef] = useState(null);

  const [allMarkers, setAllMarkers] = useState([]);

  useEffect(() => {
    console.log('Filters prop updated in MapSearchWithBounds:', filters);
  }, [filters]);

  useEffect(() => {
    // Reset allMarkers when filters change
    setAllMarkers([]);
  }, [filters]);

  useEffect(() => {
    if (params.latitude && params.longitude) {
      setCenter({
        lat: parseFloat(params.latitude),
        lng: parseFloat(params.longitude),
      });
    }
  }, [params.latitude, params.longitude]);

  const polygonPath = state.now.length
    ? state.now[0].snapshot.path.map((coord) => ({
      lat: coord.lat(),
      lng: coord.lng(),
    }))
    : [];

  const polygon = state.now.length ? state.now[0].geometry : false;

  const points = useMemo(
    () =>
      properties.map((property) => ({
        id: property.id,
        position: property.position,
        value: formatNumberToDollars(property.current_price),
        property,
      })),
    [properties]
  );

  const geometry = window.google && window.google.maps && window.google.maps.geometry;

  const markerParams = useMemo(
    () => ({
      points,
      polygonPath,
      geometry,
      polygon,
      state,
      bounds,
    }),
    [points, polygonPath, geometry, polygon, state, bounds]
  );

  const { markers } = useMarkers(markerParams);

  // Update allMarkers state
  useEffect(() => {
    setAllMarkers((prevMarkers) => {
      const markerMap = new Map(prevMarkers.map((marker) => [marker.id, marker]));
      markers.forEach((marker) => {
        if (!markerMap.has(marker.id)) {
          markerMap.set(marker.id, marker);
        }
      });
      return Array.from(markerMap.values());
    });
  }, [markers]);

  const handleBoundsChanged = useCallback(() => {
    if (mapRef) {
      const bounds = mapRef.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const newBounds = {
        ne: { lat: ne.lat(), lng: ne.lng() },
        sw: { lat: sw.lat(), lng: sw.lng() },
      };

      setBounds(newBounds);

      if (shouldUpdateOnMove) {
        onMapMove(newBounds);
      }
    }
  }, [mapRef, onMapMove, shouldUpdateOnMove]);

  const debouncedHandleBoundsChanged = useMemo(
    () => debounce(handleBoundsChanged, searchDelay),
    [handleBoundsChanged]
  );

  useEffect(() => {
    return () => {
      debouncedHandleBoundsChanged.cancel();
    };
  }, [debouncedHandleBoundsChanged]);

  const handleMapLoad = useCallback(
    (map) => {
      setMapRef(map);
      map.setCenter(center);
      map.setZoom(13); // Set your desired zoom level
    },
    [center]
  );

  const handleMarkerClick = useCallback(
    (property) => {
      setSelectedProperty((prevSelected) => {
        const newSelected = prevSelected && prevSelected.id === property.id ? null : property;
        setShouldUpdateOnMove(!newSelected);
        return newSelected;
      });
    },
    []
  );

  const handleMapClick = useCallback(() => {
    setSelectedProperty(null);
    setShouldUpdateOnMove(true);
  }, []);

  const handleMapIdle = useCallback(() => {
    debouncedHandleBoundsChanged();
  }, [debouncedHandleBoundsChanged]);

  return (
    <div className={styles.mapContainer}>
      <GoogleMap
        onLoad={handleMapLoad}
        onIdle={handleMapIdle}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        zoom={13}
        onClick={handleMapClick}
        options={{
          gestureHandling: 'greedy',
          styles: mapStyles,
          backgroundColor: '#f9f9f9',
          disableDefaultUI: false,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          clickableIcons: false,
          buildings: true,
        }}
      >
        <MarkerClusterer
          options={{
            gridSize: 45, // Smaller grid size
            minimumClusterSize: 7, // Require more markers to form a cluster
          }}
        >
          {(clusterer) =>
            allMarkers.map((marker) => (
              <Markers
                key={marker.id}
                point={marker}
                onClick={() => handleMarkerClick(marker.property)}
                isSelected={selectedProperty && selectedProperty.id === marker.property.id}
                clusterer={clusterer} // Pass the clusterer to the marker
              />
            ))
          }
        </MarkerClusterer>

        {/* Include your custom controls if necessary */}
        <UndoRedoControl drawingManager={drawingManager} state={state} dispatch={dispatch} />
      </GoogleMap>

      {isLoading && <LoadingOverlay />}
      <MarketSearchDrawer
        handleClickClose={handleClickMapDrawerClose}
        isOpen={isMapDrawerOpen}
        container={drawerContainerRef.current}
        setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
        properties={properties}
        property={selectedProperty}
      />
    </div>
  );
};

export default MapSearchWithBounds;
