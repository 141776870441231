// Financials.js
import React from 'react';
import {
  Container,
  Title,
  TableWrapper,
  TableInner,
  TableContent,
  InfoGrid,
  InfoItem,
  Label,
  Value
} from './styled';

const Financials = ({ propertyDetail }) => {
  const {
    estimatedValue,
    estimatedEquity,
    estimatedMortgageBalance,
    taxInfo,
    equityPercent,
  } = propertyDetail;

  const details = [
    {
      label: "Estimated Value",
      value: estimatedValue ? `$${estimatedValue.toLocaleString()}` : 'N/A'
    },
    {
      label: "Estimated Equity",
      value: estimatedEquity ? `$${estimatedEquity.toLocaleString()}` : 'N/A'
    },
    {
      label: "Equity Percent",
      value: equityPercent ? `${equityPercent}%` : 'N/A'
    },
    {
      label: "Mortgage Balance",
      value: estimatedMortgageBalance
        ? `$${estimatedMortgageBalance.toLocaleString()}`
        : 'N/A'
    },
    {
      label: "Assessed Value",
      value: taxInfo.assessedValue
        ? `$${taxInfo.assessedValue.toLocaleString()}`
        : 'N/A'
    },
    {
      label: "Tax Amount",
      value: taxInfo.taxAmount
        ? `$${parseFloat(taxInfo.taxAmount).toLocaleString()}`
        : 'N/A'
    },
    {
      label: "Assessment Year",
      value: taxInfo.assessmentYear || 'N/A'
    },
    {
      label: "Market Value",
      value: taxInfo.marketValue
        ? `$${taxInfo.marketValue.toLocaleString()}`
        : 'N/A'
    },
    {
      label: "Tax Exemption",
      value: propertyDetail.propertyInfo.taxExemptionHomeownerFlag ? 'Yes' : 'No'
    }
  ];

  return (
    <Container>
      <Title>Financial Details</Title>
      <TableWrapper>
        <TableInner>
          <TableContent>
            <InfoGrid>
              {details.map((item, index) => (
                <InfoItem 
                  key={item.label}
                  isEven={index % 2 === 0}
                  isLastRow={index >= details.length - 2}
                >
                  <Label>{item.label}</Label>
                  <Value>{item.value}</Value>
                </InfoItem>
              ))}
            </InfoGrid>
          </TableContent>
        </TableInner>
      </TableWrapper>
    </Container>
  );
};

export default Financials;