// src/pages/MarketSearch/MarketSearch.jsx

import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { isMobile, isDesktop } from 'react-device-detect';
import { debounce } from 'lodash';

// Constants
import { DEFAULT_FILTERS, ACTION_OPTIONS } from './utils';
import {
  COLUMNS,
  VIEW_OPTION,
  VIEW_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
} from '../../constants';

// Hooks
import usePropertySearchWithParams from '../../hooks/usePropertySearchWithParams';

// Components
import MoreFiltersDrawer from '../Map/MarketSearchMap/MarketSearchDrawer/MoreFiltersDrawer';
import { ROUTE_PATH } from '../../constants/routePath';
import BaseTypography from '../../components/BaseTypography';
import { BaseInput } from '../../components/Input';
import { BaseButton, OutlinedButton } from '../../components/Button';
import {
  Button,
  Col,
  Row,
  Segmented,
  Table,
  Modal,
  InputNumber,
} from 'antd';
import { FiltersDrawerSvg, MapPicSvg, SearchIconSvg } from '../../components/Icons';
import Icon, { RightOutlined } from '@ant-design/icons';
import MarketSearchGrid from './MarketSearchGrid';
import DropdownComponent from '../../components/DropdownComponent';
import MarketSearchMap from './MarketSearchMap';
import DesktopFilters from '../Map/componenets/DesktopFilters';

// Styles
import styles from './MarketSearch.module.scss';

const MarketSearch = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [view, setView] = useState(searchParams.get('view') || VIEW_OPTION.table);
  const isFirstLoad = useRef(true);

  // Extract search parameters
  const streetName = searchParams.get('streetName') || '';
  const searchType = searchParams.get('searchType') || 'C';

  // Define initialLatitude and initialLongitude before using them
  const initialLatitude = parseFloat(searchParams.get('latitude')) || 32.7767; // Default to Dallas, TX
  const initialLongitude = parseFloat(searchParams.get('longitude')) || -96.7970;

  // UseRef to store initialMapCenter
  const initialMapCenter = useRef({
    latitude: initialLatitude,
    longitude: initialLongitude,
  });

  // Define mapCenter as state
  const [mapCenter, setMapCenter] = useState(initialMapCenter.current);

  // State and refs
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [isFiltersActive, setIsFiltersActive] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMapDrawerOpen, setIsMapDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const drawerContainerRef = useRef(null);

  // State for the selected property
  const [selectedProperty, setSelectedProperty] = useState(null);

  // Added state variables for DesktopFilters
  const [isPricePopupVisible, setIsPricePopupVisible] = useState(false);
  const [isPropertyTypePopupVisible, setIsPropertyTypePopupVisible] = useState(false);
  const [isRoomsPopupVisible, setIsRoomsPopupVisible] = useState(false);
  const [isListingStatusPopupVisible, setIsListingStatusPopupVisible] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 2000000]);
  const [propertyType, setPropertyType] = useState('any');
  const [searchTerm, setSearchTerm] = useState('');

  // Debounced map center change handler
  const debouncedHandleMapCenterChanged = useMemo(
    () =>
      debounce((center) => {
        if (!isFirstLoad.current) {
          console.log('Map center changed (debounced):', center);

          // Update mapCenter with latitude and longitude properties
          setMapCenter({
            latitude: center.lat,
            longitude: center.lng,
          });
        }
      }, 1000),
    [isFirstLoad]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedHandleMapCenterChanged.cancel();
    };
  }, [debouncedHandleMapCenterChanged]);

  // Use apiParams for API call
  const apiParams = useMemo(() => {
    const params = Object.fromEntries(searchParams);
    const { view: _, ...rest } = params;
    let newParams = { ...rest };

    if (isFirstLoad.current) {
      // On first load, prioritize location-based search
      if (searchType === 'city') {
        const [city, state] = streetName.split(',').map((part) => part.trim());
        newParams = {
          ...newParams,
          city: city || '',
          state: state || 'TX', // Default to TX if no state provided
          // Explicitly remove lat/long on first load for city search
          latitude: undefined,
          longitude: undefined,
        };
      } else if (searchType === 'zip') {
        newParams = {
          ...newParams,
          zip: streetName,
          // Remove lat/long for zip search
          latitude: undefined,
          longitude: undefined,
        };
      } else if (searchType === 'address') {
        newParams = {
          ...newParams,
          address: streetName,
          // Remove lat/long for address search
          latitude: undefined,
          longitude: undefined,
        };
      }
    } else {
      // After first load, include map coordinates if available
      if (mapCenter.latitude !== null && mapCenter.longitude !== null) {
        newParams = {
          ...newParams,
          latitude: mapCenter.latitude,
          longitude: mapCenter.longitude,
          radius: 10, // Add a reasonable search radius
        };
      }
    }

    // Include mapCenter coordinates after the first load
    if (!isFirstLoad.current && mapCenter.latitude !== null && mapCenter.longitude !== null) {
      newParams = {
        ...newParams,
        latitude: mapCenter.latitude,
        longitude: mapCenter.longitude,
        radius: 10, // Adjust as needed
      };
    }

    // Include filters in apiParams
    if (filters) {
      if (filters.range) {
        newParams.price_min = filters.range.low;
        newParams.price_max = filters.range.high;
      }
      if (filters.bedroom) {
        newParams.bedrooms_min = filters.bedroom;
      }
      if (filters.bathroom) {
        newParams.bathrooms_min = filters.bathroom;
      }
      if (filters.propertyType && filters.propertyType !== 'any') {
        newParams.property_type = filters.propertyType;
      }
      if (searchTerm) {
        newParams.search = searchTerm;
      }
      // Add other filters as needed
    }

    return newParams;
  }, [searchParams, streetName, searchType, mapCenter, filters, searchTerm]);

  const { properties, isLoading, hasError } = usePropertySearchWithParams(apiParams);

  // Loading state for map
  const isLoadingMap = isLoading || properties.length === 0;

  // Update map center when properties are loaded
  useEffect(() => {
    if (properties.length > 0 && isFirstLoad.current) {
      // Calculate the center from the first property or the bounds of all properties
      const firstProperty = properties[0];
      if (firstProperty?.position) {
        const newCenter = {
          latitude: firstProperty.position.lat,
          longitude: firstProperty.position.lng,
        };
        initialMapCenter.current = newCenter;
        setMapCenter(newCenter);
      }
      isFirstLoad.current = false;
    }
  }, [properties]);

  // Update URL without triggering API call
  const updateUrlWithView = useCallback(
    (newView) => {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('view', newView);
      navigate(`?${newParams.toString()}`, { replace: true });
    },
    [navigate, searchParams]
  );

  // Handle view change
  const handleViewChange = useCallback(
    (newView) => {
      setView(newView);
      updateUrlWithView(newView);
    },
    [updateUrlWithView]
  );

  // Update view when URL changes, without affecting API params
  useEffect(() => {
    const urlView = searchParams.get('view');
    if (urlView && VIEW_OPTIONS.includes(urlView) && urlView !== view) {
      setView(urlView);
    }
  }, [searchParams, view]);

  const filteredProperties = useMemo(() => {
    if (!isFiltersActive) return properties;
    return properties.filter((property) => {
      const meetsRangeFilter =
        property.current_price <= filters.range.high &&
        property.current_price >= filters.range.low;
      const meetsBedFilter = property.bedrooms >= filters.bedroom;
      const meetsBathFilter = property.bathrooms >= filters.bathroom;
      return meetsRangeFilter && meetsBedFilter && meetsBathFilter;
    });
  }, [isFiltersActive, properties, filters]);

  const filtersCount = useMemo(() => {
    return isFiltersActive
      ? `(${Object.values(filters).filter((value) => value).length})`
      : '';
  }, [isFiltersActive, filters]);

  const handleClickFiltersOpen = useCallback(() => {
    setIsFiltersDrawerOpen(true);
  }, []);

  const handleClickFilterClose = useCallback(() => {
    setIsFiltersDrawerOpen(false);
  }, []);

  const handleClickMapDrawerOpen = useCallback(() => {
    setIsMapDrawerOpen(true);
  }, []);

  const handleClickMapDrawerClose = useCallback(() => {
    setIsMapDrawerOpen(false);
  }, []);

  const handleClickRow = useCallback(
    (property) => {
      navigate(`${ROUTE_PATH.DETAILS}/${property.id}`, { state: { property } });
    },
    [navigate]
  );

  // Use the debounced handler
  const handleMapCenterChanged = useCallback(
    (center) => {
      debouncedHandleMapCenterChanged(center);
    },
    [debouncedHandleMapCenterChanged]
  );

  const handleRowSelection = useCallback((selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  }, []);

  const handleModalOpen = useCallback((event) => {
    event.preventDefault();
    setIsModalOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleSaveSearch = useCallback(() => {
    const savedSearches =
      JSON.parse(localStorage.getItem('INVESTED_SAVED_SEARCHES')) ?? [];
    const saveSearchObject = {
      query: apiParams,
      date: new Date(),
      searchName,
    };

    const newSavedSearches = [...savedSearches, saveSearchObject];

    localStorage.setItem(
      'INVESTED_SAVED_SEARCHES',
      JSON.stringify(newSavedSearches)
    );

    setIsModalOpen(false);
  }, [apiParams, searchName]);

  const handleChangeSearchName = useCallback((event) => {
    const { value } = event.target;
    setSearchName(value);
  }, []);

  // Handlers for DesktopFilters
  const handlePriceFilterApply = useCallback((range) => {
    setPriceRange(range);
    setFilters(prev => ({
      ...prev,
      range: { low: range[0], high: range[1] }
    }));
    setIsFiltersActive(true);
  }, []);

  const handlePriceReset = useCallback(() => {
    setPriceRange([0, 2000000]);
    setFilters(prev => ({
      ...prev,
      range: { low: 0, high: 2000000 }
    }));
    setIsFiltersActive(true);
  }, []);

  const handlePropertyTypeApply = useCallback((type) => {
    setPropertyType(type);
    setFilters(prev => ({
      ...prev,
      propertyType: type
    }));
    setIsFiltersActive(true);
  }, []);

  const handleRoomsApply = useCallback((values) => {
    const convertToNumber = (value) => {
      if (value === 'No min' || value === 'No max' || value === null) return null;
      if (value === '5+') return 5;
      return parseInt(value, 10);
    };

    setFilters(prev => ({
      ...prev,
      bedroom: convertToNumber(values.bedrooms.min),
      bathroom: convertToNumber(values.bathrooms.min)
    }));
    setIsFiltersActive(true);
  }, []);

  const handleListingStatusApply = useCallback((values) => {
    // Update filters as needed
    setFilters(prev => ({
      ...prev,
      listingStatus: values.statuses,
      daysOnMarket: values.daysOnMarket,
      // Other filters
    }));
    setIsFiltersActive(true);
  }, []);

  const handleSearch = useCallback((value) => {
    setSearchTerm(value);
    setFilters(prev => ({
      ...prev,
      searchTerm: value
    }));
    setIsFiltersActive(true);
  }, []);

  // Validate property data
  useEffect(() => {
    properties.forEach((property) => {
      if (
        !property.position ||
        typeof property.position.lat !== 'number' ||
        typeof property.position.lng !== 'number'
      ) {
        console.warn(
          `Invalid position for property ID ${property.id}:`,
          property.position
        );
      }
    });
  }, [properties]);

  return (
    <div className={styles.container} ref={drawerContainerRef}>
      {isDesktop && (
        <>
          <DesktopFilters
            isPricePopupVisible={isPricePopupVisible}
            setIsPricePopupVisible={setIsPricePopupVisible}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            handlePriceFilterApply={handlePriceFilterApply}
            handlePriceReset={handlePriceReset}
            isPropertyTypePopupVisible={isPropertyTypePopupVisible}
            setIsPropertyTypePopupVisible={setIsPropertyTypePopupVisible}
            propertyType={propertyType}
            handlePropertyTypeApply={handlePropertyTypeApply}
            isRoomsPopupVisible={isRoomsPopupVisible}
            setIsRoomsPopupVisible={setIsRoomsPopupVisible}
            handleRoomsApply={handleRoomsApply}
            isListingStatusPopupVisible={isListingStatusPopupVisible}
            setIsListingStatusPopupVisible={setIsListingStatusPopupVisible}
            handleListingStatusApply={handleListingStatusApply}
            handleClickFiltersOpen={handleClickFiltersOpen}
            PROPERTY_TYPE_OPTIONS={PROPERTY_TYPE_OPTIONS}
            onSearch={handleSearch}
            searchTerm={searchTerm}
            filters={filters}
          />

          <Row className={styles.viewOptions} justify="end" align="middle" style={{ marginBottom: '10px' }}>
            <Col>
              <Segmented
                value={view}
                options={VIEW_OPTIONS}
                onChange={handleViewChange}
                block
              />
            </Col>
          </Row>
        </>
      )}

      {isMobile && (
        <>
          <div className={styles.header}>
            <div>
              <span className={styles.grayNav}>
                {/* Breadcrumbs  */}
                <Link to={ROUTE_PATH.DASHBOARD} className={styles.link}>
                  Home <RightOutlined className={styles.icon} />
                </Link>
              </span>
              <span className={styles.blackNav}>Search</span>
            </div>

            <div>
              {/* View Buttons that changes from Maps, To List, To Grid */}
              <Segmented
                value={view}
                options={VIEW_OPTIONS}
                onChange={handleViewChange}
                style={{ margin: '10px 0' }}
                block
              />

              {/* Button to open filters drawer */}
              <OutlinedButton
                className={styles.btnFilters}
                onClick={handleClickFiltersOpen}
              >
                <Icon component={FiltersDrawerSvg} />
              </OutlinedButton>
            </div>
          </div>
        </>
      )}

      <div className={styles.searchContent}>
        {view === VIEW_OPTION.grid && (
          <>
            <MarketSearchGrid properties={filteredProperties} />
          </>
        )}

        {view === VIEW_OPTION.table && (
          <div>
            <Table
              className={styles.searchTable}
              style={{ backgroundColor: 'white' }}
              pagination={{
                total: filteredProperties.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total}`,
              }}
              size="large"
              rowKey={(record) => record.id}
              columns={COLUMNS}
              dataSource={filteredProperties}
              loading={isLoading}
              rowClassName={styles.row}
              onRow={(record) => {
                return { onClick: () => handleClickRow(record) };
              }}
              rowSelection={{
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) =>
                  handleRowSelection(selectedRowKeys, selectedRows),
              }}
            />
          </div>
        )}

        {view === VIEW_OPTION.map && (
          <MarketSearchMap
            drawerContainerRef={drawerContainerRef}
            properties={filteredProperties}
            setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
            isMapDrawerOpen={isMapDrawerOpen}
            handleClickMapDrawerClose={handleClickMapDrawerClose}
            initialCenter={initialMapCenter.current}
            selectedProperty={selectedProperty}
            setSelectedProperty={setSelectedProperty}
            isLoadingMap={isLoadingMap}
            onMapCenterChanged={handleMapCenterChanged}
          />
        )}

        {/* Action Buttons for table view */}
        {view !== VIEW_OPTION.map || view !== VIEW_OPTION.grid && (
          <div className={styles.bottom}>
            <DropdownComponent
              className={styles.actions}
              items={ACTION_OPTIONS}
              placement="top"
              style={{ marginRight: '10px' }}
            >
              Actions
            </DropdownComponent>

            <BaseButton onClick={handleModalOpen}>Save Search</BaseButton>
          </div>
        )}
      </div>

      <Modal
        okText="Save"
        onCancel={handleCancel}
        onOk={handleSaveSearch}
        open={isModalOpen}
        title="Save Search"
        okButtonProps={{ disabled: !searchName }}
      >
        <div>
          <BaseTypography align="left">Search Name</BaseTypography>
          <BaseInput
            onChange={handleChangeSearchName}
            value={searchName}
            placeholder="Enter search name"
          />
        </div>
      </Modal>

      <MoreFiltersDrawer
        handleClickFilterClose={handleClickFilterClose}
        isFilterOpen={isFiltersDrawerOpen}
        container={drawerContainerRef.current}
        setFilters={setFilters}
        currentHighRange={properties.length ? Math.max(...properties.map(p => p.current_price)) : 2000000}
        setIsFiltersActive={setIsFiltersActive}
        searchOption={view}
        VIEW_OPTIONS={VIEW_OPTIONS}
        handleChange={handleViewChange}
      />
    </div>
  );
};

export default MarketSearch;
