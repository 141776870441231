// usePropertySearchWithParams.js

import { useEffect, useState } from 'react';
import axios from 'axios';
import { propertyFormat } from '../utils/propertyFormat';
import { getStreetViewImage } from '../utils/getStreetView';
import { api } from '../constants/api';

const usePropertySearchWithParams = (apiParams) => {
  const [properties, setProperties] = useState([]);
  const [propertiesWithImages, setPropertiesWithImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        setIsLoading(true);

        // Remove undefined or null parameters
        const cleanParams = Object.entries(apiParams).reduce((acc, [key, value]) => {
          if (value !== undefined && value !== null) {
            acc[key] = value;
          }
          return acc;
        }, {});

        console.log('Fetching properties with cleaned params:', cleanParams);
        
        const response = await axios.post(api.listing.propertySearch, cleanParams);

        if (!response.data.length) {
          console.log('No properties returned from API');
          setProperties([]);
          return;
        }

        const data = propertyFormat(response.data);
        setProperties(data);
      } catch (error) {
        console.error('API call failed:', error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (apiParams && Object.keys(apiParams).length > 0) {
      fetchProperties();
    } else {
      console.log('No API parameters provided, skipping API call');
    }
  }, [apiParams]);

  // Fetch Street View images (unchanged)
  useEffect(() => {
    const fetchStreetViewImages = async () => {
      const propertiesWithImages = await Promise.all(
        properties.map(async (property) => {
          const streetViewImage = await getStreetViewImage({
            lat: property.position.lat,
            lng: property.position.lng,
          });

          return {
            ...property,
            streetViewImage,
          };
        })
      );

      setPropertiesWithImages(propertiesWithImages);
    };

    if (properties.length > 0) {
      fetchStreetViewImages();
    }
  }, [properties]);

  return { properties: propertiesWithImages, isLoading, hasError };
};

export default usePropertySearchWithParams;