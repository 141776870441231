import React, { useState, useEffect, useRef } from 'react';
import { Container } from './styled';
import ListingItem from './components/homeSearch';
import { api } from '../../constants/api';
import Footer from '../../layouts/PublicLayout/components/Footer';

const HomeSearch = () => {
  const [lendingTerms, setLendingTerms] = useState([]);
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const fetchLendingTerms = async () => {
        try {
          const response = await fetch(api.buyers.getLendedHomes);
          const data = await response.json();
          setLendingTerms(data);
        } catch (error) {
          console.error('Error fetching lending terms:', error);
        }
      };

      fetchLendingTerms();

      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  return (
    <div>
      <Container>
        <ListingItem lendingTerms={lendingTerms} />
      </Container>
      <Footer />
    </div>
  );
};

export default HomeSearch;