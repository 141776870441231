// Details.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, Marker } from '@react-google-maps/api'; // Updated import

// Styles
import styles from './Details.module.scss';

// Constants
import { COLORS } from '../../constants/colors';

// Utils
import { formatNumberToDollars } from '../../utils/numberFormat';
import { getStreetViewImage } from '../../utils/getStreetView';

// Hooks
import useGetPropertyDetail from '../../hooks/useGetPropertyDetail';

// Components
import { Button, Col, Row } from 'antd';
import Icon, { LeftOutlined } from '@ant-design/icons';
import BaseTypography from '../../components/BaseTypography';
import Card from './Card';
import Specifications from './Specifications';
import PropertyOwner from './PropertyOwner/PropertyOwner';
import ListingGallery from './ListingGallery';
import {
  LendSvg,
  PropertyDetailsSvg,
} from '../../components/Icons';
import Spinner from '../../components/Spinner';
import { isMobile } from 'react-device-detect';
import { BaseButton } from '../../components/Button';
import ButtonsGroup from './ButtonsGroup';
import DescriptionDetails from './DescriptionDetails';

// Tabs Components
import { Tabs } from 'antd';
import GeneralInfo from './GeneralInfo';
import Financials from './Financials';
import OwnerDetails from './OwnerDetails';
import MLSHistory from './MLSHistory';
import SchoolsInfo from './SchoolsInfo';
import NeighborhoodInfo from './NeighborhoodInfo';
import LegalInformation from './LegalInformation';

// Define map container style
const mapContainerStyle = {
  width: '100%',
  height: '400px', // Adjust height as needed
};

// Define a default center in case propertyDetail.position is unavailable
const defaultCenter = { lat: 37.7749, lng: -122.4194 }; // Example: San Francisco

// SVG to Data URL
const svgIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="56" viewBox="0 0 48 56" fill="none">
  <!-- Main rectangle with rounded corners -->
  <path d="M8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H29.4142L24 53.4142L18.5858 48H8C3.58172 48 0 44.4183 0 40V8C0 3.58172 3.58172 0 8 0Z" fill="#005EB8"/>
  
  <!-- House icon -->
  <g clipPath="url(#clip0_29422_2735)">
    <path
      d="M33.3333 19.6131L26.2226 14.0824C25.5986 13.5969 24.8306 13.3334 24.04 13.3334C23.2494 13.3334 22.4813 13.5969 21.8573 14.0824L14.7453 19.6131C14.3179 19.9454 13.9721 20.3711 13.7343 20.8575C13.4965 21.344 13.373 21.8783 13.3733 22.4197V32.0197C13.3733 32.727 13.6542 33.4053 14.1543 33.9054C14.6544 34.4054 15.3327 34.6864 16.04 34.6864H32.04C32.7472 34.6864 33.4255 34.4054 33.9256 33.9054C34.4257 33.4053 34.7066 32.727 34.7066 32.0197V22.4197C34.7066 21.3224 34.2 20.2864 33.3333 19.6131Z"
      fill="white"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3334 28C26.3867 29.7773 21.6107 29.7773 18.6667 28"
      stroke="#005EB8"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
  
  <!-- Clip path for house icon -->
  <defs>
    <clipPath id="clip0_29422_2735">
      <rect width="32" height="32" fill="white" transform="translate(8 8)" />
    </clipPath>
  </defs>
</svg>
`;

// Encode the SVG to a data URL
const encodedSvg = encodeURIComponent(svgIcon)
  .replace(/'/g, '%27')
  .replace(/"/g, '%22');

const dataUrl = `data:image/svg+xml;charset=UTF-8,${encodedSvg}`;

const Details = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [streetViewImage, setStreetViewImage] = useState(null);
  const { propertyDetail, isLoading } = useGetPropertyDetail(id);
  const [imageLoading, setImageLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('general');

  // Extract tab parameter from URL
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam) {
      setActiveTab(tabParam);
    }
  }, []);

  // Fetch Street View image when propertyDetail changes
  useEffect(() => {
    const fetchStreetViewImage = async () => {
      if (propertyDetail?.propertyInfo?.address) {
        setImageLoading(true);
        const { address, city, state } = propertyDetail.propertyInfo.address;
        const fullAddress = `${address}, ${city}, ${state}`;
        const image = await getStreetViewImage({ address: fullAddress });
        setStreetViewImage(image);
        setImageLoading(false);
      }
    };

    fetchStreetViewImage();
  }, [propertyDetail]);

  // Handle navigation back
  const handleClickBack = () => {
    navigate(-1);
  };

  // Handle "Lend" button click
  const handleLendClick = () => {
    navigate('/lend', {
      state: {
        price: propertyDetail.mlsListingPrice,
        address: propertyDetail.propertyInfo.address.address,
        listingName: propertyDetail.neighborhood?.name || propertyDetail.propertyType,
        streetViewImage,
        propertyDetail,
      },
    });
  };

  // Show spinner while loading data
  if (isLoading || !propertyDetail) {
    return <Spinner />;
  }

  const { propertyInfo, mlsHistory } = propertyDetail;
  const agent = mlsHistory && mlsHistory[0]?.agentName;

  // Ensure position is correctly formatted
  const position =
    propertyDetail.position &&
    typeof propertyDetail.position.lat === 'number' &&
    typeof propertyDetail.position.lng === 'number'
      ? propertyDetail.position
      : defaultCenter;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/* 
          Uncomment this section if you want a back button
          <div className={styles.navigation}>
            <Button
              onClick={handleClickBack}
              size="large"
              type="link"
              style={{ padding: 0 }}
              icon={<LeftOutlined />}
            >
              Back to list
            </Button>
          </div>
        */}

        {/* Title with Address */}
        <Row align="top" className={styles.titleContainer}>
          <Col xs={24} lg={12}>
            <BaseTypography
              className={styles.listingName}
              fontSize={isMobile ? '32px' : '40px'}
              align="left"
            >
              {propertyDetail.neighborhood?.name || propertyDetail.propertyType}
            </BaseTypography>
            <BaseTypography
              className={styles.listingAddress}
              fontSize={isMobile ? '16px' : '20px'}
              align="left"
              color={COLORS.$mainDark}
            >
              {propertyInfo.address.address}
            </BaseTypography>
          </Col>
          <Col xs={24} lg={12}>
            <ButtonsGroup />
          </Col>
        </Row>

        {/* Image Section */}
        <ListingGallery streetViewImage={streetViewImage} />

        {/* Specifications, Agent Info, and Lend Button */}
        <Row className={styles.cardContainer} gutter={20}>
          <Col xs={24} lg={16}>
            <Specifications
              listingId={propertyDetail.id}
              propertyType={propertyDetail.propertyType}
              price={formatNumberToDollars(propertyDetail.mlsListingPrice)}
              neighborhood={propertyDetail.neighborhood?.name}
              mlsNumber={propertyDetail.mlsNumber}
            />
            {isMobile && <DescriptionDetails />}

            {/* Tabs Section */}
            <Tabs activeKey={activeTab} onChange={setActiveTab} style={{ marginBottom: '20px' }}>
              <Tabs.TabPane tab="General Info" key="general">
                <GeneralInfo propertyDetail={propertyDetail} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Financials" key="financials">
                <Financials propertyDetail={propertyDetail} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Owner Details" key="owner">
                <OwnerDetails ownerInfo={propertyDetail.ownerInfo} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="MLS History" key="mlsHistory">
                <MLSHistory mlsHistory={propertyDetail.mlsHistory} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Schools" key="schools">
                <SchoolsInfo schools={propertyDetail.schools} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Neighborhood" key="neighborhood">
                <NeighborhoodInfo neighborhood={propertyDetail.neighborhood} />
              </Tabs.TabPane>
              {(propertyDetail.lien || propertyDetail.judgment || propertyDetail.taxLien) && (
                <Tabs.TabPane tab="Legal Information" key="legalInformation">
                  <LegalInformation
                    lien={propertyDetail.lien}
                    judgment={propertyDetail.judgment}
                    taxLien={propertyDetail.taxLien}
                  />
                </Tabs.TabPane>
              )}
            </Tabs>

            {/* Agent Information */}
            {agent && <PropertyOwner contact={agent} owner={mlsHistory[0]?.agentOffice} />}

            {/* Lend on This Property Section for Mobile */}
            {isMobile && (
              <div className={styles.investWrapper}>
                <div className={styles.investTitle}>List Price</div>
                <div className={styles.investPrice}>
                  {formatNumberToDollars(propertyDetail.mlsListingPrice)}
                </div>
                <BaseButton
                  className={styles.investBtn}
                  icon={<PropertyDetailsSvg />}
                  onClick={handleLendClick}
                >
                  Lend on this property
                </BaseButton>
              </div>
            )}

            {/* Map Section */}
            <Card title="Map">
              <div className={styles.mapContainer}>
                <GoogleMap mapContainerStyle={mapContainerStyle} center={position} zoom={13}>
                  <Marker
                    position={position}
                    icon={{
                      url: dataUrl,
                      scaledSize: new window.google.maps.Size(30, 30), // Adjust size as needed
                    }}
                  />
                </GoogleMap>
              </div>
              <Button
                onClick={handleClickBack}
                size="large"
                type="link"
                style={{ padding: 0, marginTop: '10px' }}
                icon={<LeftOutlined />}
              >
                See more listings in {propertyInfo.address.city}
              </Button>
            </Card>
          </Col>

          {/* Lend on This Property Section for Desktop */}
          {!isMobile && (
            <Col xs={24} lg={8}>
              <div className={styles.investContainer}>
                <BaseTypography className={styles.listPrice} align="left" fontSize="14px">
                  List Price
                </BaseTypography>
                <span className={styles.listPriceValue}>
                  {formatNumberToDollars(propertyDetail.mlsListingPrice)}
                </span>

                <Button
                  icon={<Icon className={styles.lendIcon} component={LendSvg} />}
                  className={styles.lendButton}
                  type="primary"
                  size="large"
                  onClick={handleLendClick}
                >
                  Lend On This Property
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Details;
