import React from 'react';
import { Image, Spin } from 'antd';
import styles from './ListingGallery.module.scss';
import defaultImage from '../../../assets/images/listing-grid-example.png';

const ListingGallery = ({ streetViewImage }) => {
  return (
    <div className={styles.galleryContainer}>
      <Image
        width="100%"
        height="auto"
        src={streetViewImage || defaultImage}
        fallback={defaultImage}
        placeholder={
          <div className={styles.imagePlaceholder}>
            <Spin size="large" />
          </div>
        }
      />
    </div>
  );
};

export default ListingGallery;
