// LegalInformation.js

import React from 'react';
import { Descriptions } from 'antd';
import { 
  Container 
} from './styled.js';

const LegalInformation = ({ lien, judgment, taxLien }) => {
  return (
    <Container>
      <Descriptions title="Legal Information" bordered column={1}>
        <Descriptions.Item label="Lien">
          {lien ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Judgment">
          {judgment ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Tax Lien">
          {taxLien ? 'Yes' : 'No'}
        </Descriptions.Item>
      </Descriptions>
    </Container>
  );
};

export default LegalInformation;
