// styles.js
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SchoolsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SchoolCard = styled.div`
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

export const SchoolName = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
`;

export const SchoolDetails = styled.div`
  font-size: 0.875rem;
  color: #4b5563;
  margin-top: 0.5rem;

  p {
    margin: 0.25rem 0;
  }
`;

export const ExpandButton = styled.button`
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  background-color: white;
  color: #374151;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #f9fafb;
  }

  &:active {
    background-color: #f3f4f6;
  }
`;