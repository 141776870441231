// GeneralInfo.styled.js
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
`;

export const TableInner = styled.div`
  display: inline-block;
  min-width: 100%;
`;

export const TableContent = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InfoItem = styled.div`
  padding: 1rem;
  background-color: white;
  border-bottom: ${props => !props.isLastRow ? '1px solid #e5e7eb' : 'none'};

  @media (min-width: 768px) {
    ${props => props.isEven && `
      border-right: 1px solid #e5e7eb;
    `}
  }
`;

export const Label = styled.span`
  display: block;
  font-size: 0.975rem;
  font-weight: 700;
  color: #6b7280;
`;

export const Value = styled.span`
  display: block;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #111827;
`;