import React, { useState } from 'react';
import { 
  Drawer, Space, Flex, Button, Slider, Radio, Select
} from 'antd';
import { Checkbox } from 'antd';
import BaseTypography from '../../../../../components/BaseTypography';
import styles from './MoreFiltersDrawer.module.scss';

const MoreFiltersDrawer = ({
  handleClickFilterClose,
  isFilterOpen,
  container,
  setFilters,
  setIsFiltersActive,
  currentHighRange,
  searchOption,
  VIEW_OPTIONS,
  handleChange,
  onFiltersApply
}) => {
  // State for all filters
  const [priceRange, setPriceRange] = useState([0, currentHighRange]);
  const [propertyType, setPropertyType] = useState('any');
  const [rooms, setRooms] = useState({
    bedrooms: { min: 'No min', max: 'No max' },
    bathrooms: { min: 'No min', max: 'No max' }
  });
  const [range, setRange] = useState({ 
    low: 0, 
    high: currentHighRange 
  });
  const [listingStatus, setListingStatus] = useState({
    status: 'Any - for sale',
    filters: {
      hidePending: false,
      hideForeclosures: false,
      hide55Plus: false,
      openHouse: false,
      virtualTours: false,
      threeDTours: false
    },
    daysOnMarket: 'Any'
  });

  const options = ['No min', '1', '2', '3', '4', '5+'];
  const statusOptions = [
    'Any - for sale',
    'Existing homes',
    'New construction',
    'Foreclosures',
    'Recently sold',
    '55+ community'
  ];
  const daysOptions = ['Any', 'Today', '7', '14', '21', '30'];

  // Update the price change handler to maintain the range structure
  const handlePriceChange = (value) => {
    setRange({
      low: value[0],
      high: value[1]
    });
  };

  const handleRoomsChange = (type, minMax, value) => {
    setRooms(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [minMax]: value
      }
    }));
  };

  const handleClickApply = () => {
    setIsFiltersActive(true);
    // Convert values for API
    const convertToNumber = (value) => {
      if (value === 'No min' || value === 'No max' || value === null) return null;
      if (value === '5+') return 5;
      return parseInt(value, 10);
    };

    const newFilters = {
      range: {
        low: range.low,
        high: range.high
      },
      property_type: propertyType,
      bedroom: convertToNumber(rooms.bedrooms.min),
      bathroom: convertToNumber(rooms.bathrooms.min),
      bathrooms_min: convertToNumber(rooms.bathrooms.min),
      bathrooms_max: convertToNumber(rooms.bathrooms.max),
      listing_status: listingStatus.status,
      days_on_market: listingStatus.daysOnMarket === 'Any' ? null : parseInt(listingStatus.daysOnMarket),
      ...listingStatus.filters
    };

    setFilters(newFilters);
    if (onFiltersApply) {
      onFiltersApply(newFilters);
    }
    handleClickFilterClose();
  };

  const handleClickReset = () => {
    setIsFiltersActive(false);
    setRange({
      low: 0,
      high: currentHighRange
    });
    setPropertyType('any');
    setRooms({
      bedrooms: { min: 'No min', max: 'No max' },
      bathrooms: { min: 'No min', max: 'No max' }
    });
    setListingStatus({
      status: 'Any - for sale',
      filters: {
        hidePending: false,
        hideForeclosures: false,
        hide55Plus: false,
        openHouse: false,
        virtualTours: false,
        threeDTours: false
      },
      daysOnMarket: 'Any'
    });
    handleClickFilterClose();
  };

  return (
    <Drawer
      width={475}
      closable={false}
      onClose={handleClickFilterClose}
      open={isFilterOpen}
      getContainer={container}
      rootStyle={{ position: 'fixed' }}
      destroyOnClose={true}
      footer={
        <div className={styles.footer}>
          <Flex justify="space-between">
            <Button className={styles.footerButtons} onClick={handleClickReset}>
              Reset
            </Button>
            <Button type="primary" className={styles.footerButtons} onClick={handleClickApply}>
              Apply
            </Button>
          </Flex>
        </div>
      }
    >
      <div className={styles.moreFiltersContainer}>
        <BaseTypography className={styles.moreFiltersTitle} fontSize="24px" align="left">
          More Filters
        </BaseTypography>

        {/* Price Range Section */}
        <div className={styles.section}>
          <BaseTypography className={styles.sectionTitle} fontSize="16px">
            Price Range
          </BaseTypography>
          <Slider
            range
            value={[range.low, range.high]}
            max={currentHighRange}
            onChange={handlePriceChange}
          />
          <Flex justify="space-between">
            <span>${range.low.toLocaleString()}</span>
            <span>${range.high.toLocaleString()}</span>
          </Flex>
        </div>

        {/* Property Type Section */}
        <div className={styles.section}>
          <BaseTypography className={styles.sectionTitle} fontSize="16px">
            Property Type
          </BaseTypography>
          <Radio.Group value={propertyType} onChange={e => setPropertyType(e.target.value)}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Radio value="any">Any</Radio>
              <Radio value="SFR">Single Family Residence</Radio>
              <Radio value="condo">Condo</Radio>
              <Radio value="townhouse">Townhouse</Radio>
              <Radio value="land">Land</Radio>
            </Space>
          </Radio.Group>
        </div>

        {/* Rooms Section */}
        <div className={styles.section}>
          <BaseTypography className={styles.sectionTitle} fontSize="16px">
            Rooms
          </BaseTypography>
          
          <div className={styles.roomsSelect}>
            <div>
              <BaseTypography style={{ textAlign: 'left'}} fontSize="14px">Bedrooms</BaseTypography>
              <Flex gap={8}>
                <Select
                  style={{ width: 120 }}
                  value={rooms.bedrooms.min}
                  onChange={value => handleRoomsChange('bedrooms', 'min', value)}
                  options={options.map(opt => ({ value: opt, label: opt }))}
                />
                <span>to</span>
                <Select
                  style={{ width: 120 }}
                  value={rooms.bedrooms.max}
                  onChange={value => handleRoomsChange('bedrooms', 'max', value)}
                  options={options.map(opt => ({ value: opt, label: opt }))}
                />
              </Flex>
            </div>
            
            <div style={{ marginTop: 16 }}>
              <BaseTypography style={{ textAlign: 'left'}} fontSize="14px">Bathrooms</BaseTypography>
              <Flex gap={8}>
                <Select
                  style={{ width: 120 }}
                  value={rooms.bathrooms.min}
                  onChange={value => handleRoomsChange('bathrooms', 'min', value)}
                  options={options.map(opt => ({ value: opt, label: opt }))}
                />
                <span>to</span>
                <Select
                  style={{ width: 120 }}
                  value={rooms.bathrooms.max}
                  onChange={value => handleRoomsChange('bathrooms', 'max', value)}
                  options={options.map(opt => ({ value: opt, label: opt }))}
                />
              </Flex>
            </div>
          </div>
        </div>

        {/* Listing Status Section */}
        <div className={styles.section}>
          <BaseTypography className={styles.sectionTitle} fontSize="16px">
            Listing Status
          </BaseTypography>
          
          <Radio.Group 
            value={listingStatus.status}
            onChange={e => setListingStatus(prev => ({ ...prev, status: e.target.value }))}
            style={{ marginBottom: '10px'}}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {statusOptions.map(status => (
                <Radio key={status} value={status}>{status}</Radio>
              ))}
            </Space>
          </Radio.Group>

          <div style={{ marginTop: 16 }}>
            <BaseTypography style={{ textAlign: 'left'}} fontSize="14px">Days on Market</BaseTypography>
            <Space wrap style={{ marginTop: 8 }}>
              {daysOptions.map(days => (
                <Button
                  key={days}
                  type={listingStatus.daysOnMarket === days ? 'primary' : 'default'}
                  onClick={() => setListingStatus(prev => ({ ...prev, daysOnMarket: days }))}
                >
                  {days}
                </Button>
              ))}
            </Space>
          </div>

          <Space direction="vertical" style={{ width: '100%', marginTop: 16 }}>
            {Object.entries(listingStatus.filters).map(([key, value]) => (
              <Checkbox
                key={key}
                checked={value}
                onChange={e => setListingStatus(prev => ({
                  ...prev,
                  filters: {
                    ...prev.filters,
                    [key]: e.target.checked
                  }
                }))}
              >
                {key.replace(/([A-Z])/g, ' $1').toLowerCase()}
              </Checkbox>
            ))}
          </Space>
        </div>
      </div>
    </Drawer>
  );
};

export default MoreFiltersDrawer;