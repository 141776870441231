// src/components/styled.js
import styled from 'styled-components';
import { Row, Col, Flex, Image, Menu, Button } from 'antd';
import { COLORS } from '../../constants/colors';

export const StyledHeader = styled.header`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
  border-bottom: 2px solid ${COLORS.$blueShades96};
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: ${COLORS.$black};

  @media (max-width: 768px) {
    flex-shrink: 0;
    margin-right: 10px;
  }

  img {
    height: 20px; // Increased from 30px to 40px
    width: auto;
    margin-right: 10px;
    object-fit: contain;
    align-self: flex-end;
  }

span {
    white-space: nowrap; // Prevent text wrapping
    
    @media (max-width: 480px) {
      font-size: 22px; // Reduce font size on very small screens
      font-weight: 600;
    }
  }
`;

export const DesktopMenu = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledMenu = styled(Menu)`
  border-bottom: none;
  margin-left: 24px;
  flex-grow: 1;

  .ant-menu-item-selected {
    border-bottom: 2px solid ${COLORS.$colorPrimary};
  }

  .ant-menu-overflow-item {
    display: inline-block !important;
  }

  .ant-menu-overflow-item-rest {
    display: none !important;
  }
`;

export const RightMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  margin-left: 14px;
  font-size: 14px;
`;

export const StyledRow = styled(Row)`
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Keep min-height for full coverage
  position: relative; // Add this
`;



export const StyledContent = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 0 auto;
  width: fit-content;
`;

export const StyledImage = styled(Image)`
  && {
    margin: 0 auto 80px auto;
    width: fit-content;
    display: flex;
  }
`;

export const ContentWrapper = styled.div`
  padding-top: 50px; // Add this to account for fixed header
  flex: 1;
  width: 100%;
  position: relative; // Add this
`;

export const StyledWrapper = styled(Col)`
  background: ${COLORS.$blueShades98};
  padding-top: 135px;
`;

export const StyledSquare = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  border-top: 300px solid ${COLORS.$blueShades92};
  border-right: 300px solid transparent;
`;

export const StyledCol = styled(Col)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FooterContainer = styled.footer`
  background: #f8f9fa;
  padding: 40px 0;
  border-top: 1px solid #dee2e6;
  margin-top: 40px;
`;

export const FooterRow = styled(Row)`
  max-width: 1200px;
  margin: 0 auto;
`;

export const FooterColumn = styled(Col)`
  text-align: left;

  @media (max-width: 576px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const FooterHeading = styled.h3`
  font-size: 16px;
  margin-bottom: 16px;
  color: #333;
`;

export const FooterLink = styled.a`
  display: block;
  color: #007bff;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const FooterLogoText = styled.span`
  font-size: 20px;
  font-weight: bold;
`;