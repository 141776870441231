import styled from 'styled-components';
import { Layout, Row, Col, Button, Flex, Select } from 'antd';
import { Link } from 'react-router-dom';

const { Header: AntHeader } = Layout;

export const Header = styled(AntHeader)`
  color: #000929;
  height: 77px;
  padding: 0 25px;
  line-height: 64px;
  background-color: #ffffff;
  border-bottom: 1px solid #dee8f7;
  text-align: center;
`;

export const HeaderContainer = styled(Row)`
  height: 100%;
  align-items: center;
`;

export const LinkStyle = styled(Link)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.gray};
  font-weight: 700;
  font-size: 14px;
  gap: 16px;
  opacity: 0.7;
  cursor: pointer;
`;

export const HeaderTitle = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
`;

export const HeaderProfile = styled(Flex)`
  align-items: center;
  justify-content: end;
`;

export const CustomButton = styled(Button)`
  background: ${props => props.theme.colors.blueShades98};
  border: 0;

  .ant-btn {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    padding-right: 10px;
  }
`;

export const CustomSelect = styled(Select)`
  .ant-select-selector {
    height: 48px !important;
    display: flex;
    align-items: center !important;
    padding-right: 12px; /* Adjust padding if necessary */
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center !important;
  }

  .ant-select-arrow {
    margin-top: 0;
    top: 50% !important;
    transform: translateY(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Optional: Adjust the overall height if needed */
  height: 48px;

  /* Ensure the input inside the Select aligns vertically */
  .ant-select-selection-search-input {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const Divider = styled.div`
  border: 1px solid #dee8f7;
  height: 40px;
`;

export const SearchField = styled(Col)`
  display: flex;
  justify-content: center;
  max-width: 800px; /* Reduced from 813px */
  width: 100%;
  margin: auto;

  .search-type-select {
    .ant-select-arrow {
      top: 50% !important;
      transform: translateY(-50%) !important;
      margin: 0 !important;
    }
  }

  @media screen and (max-width: 1440px) {
    max-width: 500px; /* Reduced from 600px */
  }

  @media screen and (max-width: 1024px) {
    max-width: 400px; /* New breakpoint for tablets */
  }

  @media screen and (max-width: 768px) {
    max-width: 100%; /* Full width on mobile */
    padding: 0 10px; /* Optional padding */
    max-width: 250px;
  }

  .ant-input-prefix {
    margin-right: 8px; /* Reduced from 16px */
  }

  .ant-input-affix-wrapper {
    padding: 8px 12px; /* Reduced from 12px 16px */
    border: 1px solid #dfdfdf; /* Reduced border width */
    background: #f7fbfd;

    input {
      font-size: 14px; /* Reduced font size */
      color: ${(props) => props.theme.colors.mainDark50};
    }
  }

  .anticon svg path {
    stroke: ${(props) => props.theme.colors.colorPrimary};
  }
`;

export const CustomCol = styled(Col)`
  @media screen and (max-width: 800px) {
    margin-inline-start: 0 !important;
  }
`;
