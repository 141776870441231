// MLSHistory.js
import React from 'react';
import { Table } from 'antd';

const MLSHistory = ({ mlsHistory }) => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'listingDate',
      key: 'listingDate',
      render: (date) => (date ? new Date(date).toLocaleDateString() : 'N/A'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Price',
      dataIndex: 'listingPrice',
      key: 'listingPrice',
      render: (price) =>
        price ? `$${parseFloat(price).toLocaleString()}` : 'N/A',
    },
    {
      title: 'Agent',
      dataIndex: 'agentName',
      key: 'agentName',
    },
    {
      title: 'Office',
      dataIndex: 'agentOffice',
      key: 'agentOffice',
    },
  ];

  return (
    <Table
      dataSource={mlsHistory}
      columns={columns}
      rowKey="seqNo"
      pagination={{ pageSize: 5 }}
    />
  );
};

export default MLSHistory;
