import React, { useState } from 'react';
import { InputNumber, Form, Alert } from 'antd';
import {
  CalculatorContainer,
  CalculatorTitle,
  FormItem,
  ResultContainer,
  ResultItem,
} from './Styled';
import { StyledButton } from '../../Lend/styled';

const MortgageNoteCalculator = () => {
  const [form] = Form.useForm();
  const [results, setResults] = useState({
    calculatedPrincipal: null,
    calculatedPayment: null,
    calculatedTerm: null,
  });
  const [error, setError] = useState(null);

  const onFinish = (values) => {
    const principal =
      values.principal !== undefined ? parseFloat(values.principal) : NaN;
    const payment =
      values.payment !== undefined ? parseFloat(values.payment) : NaN;
    const interestRate =
      values.interestRate !== undefined
        ? parseFloat(values.interestRate)
        : NaN;
    const term = values.term !== undefined ? parseFloat(values.term) : NaN;

    const monthlyRate = interestRate / 100 / 12;

    try {
      if (
        !isNaN(principal) &&
        !isNaN(interestRate) &&
        !isNaN(term) &&
        isNaN(payment)
      ) {
        // Calculate payment
        const numberOfPayments = term * 12;

        if (monthlyRate === 0) {
          // Zero interest rate
          const calculatedPayment = principal / numberOfPayments;
          setResults({
            calculatedPrincipal: principal,
            calculatedPayment,
            calculatedTerm: term,
          });
          setError(null);
        } else {
          const calculatedPayment =
            (principal * monthlyRate) /
            (1 - Math.pow(1 + monthlyRate, -numberOfPayments));
          setResults({
            calculatedPrincipal: principal,
            calculatedPayment,
            calculatedTerm: term,
          });
          setError(null);
        }
      } else if (
        isNaN(principal) &&
        !isNaN(payment) &&
        !isNaN(interestRate) &&
        !isNaN(term)
      ) {
        // Calculate principal
        const numberOfPayments = term * 12;

        if (monthlyRate === 0) {
          // Zero interest rate
          const calculatedPrincipal = payment * numberOfPayments;
          setResults({
            calculatedPrincipal,
            calculatedPayment: payment,
            calculatedTerm: term,
          });
          setError(null);
        } else {
          const calculatedPrincipal =
            (payment * (1 - Math.pow(1 + monthlyRate, -numberOfPayments))) /
            monthlyRate;
          setResults({
            calculatedPrincipal,
            calculatedPayment: payment,
            calculatedTerm: term,
          });
          setError(null);
        }
      } else if (
        !isNaN(principal) &&
        !isNaN(payment) &&
        !isNaN(interestRate) &&
        isNaN(term)
      ) {
        // Calculate term

        if (monthlyRate === 0) {
          // Zero interest rate
          const calculatedTerm = principal / payment / 12;
          setResults({
            calculatedPrincipal: principal,
            calculatedPayment: payment,
            calculatedTerm,
          });
          setError(null);
        } else {
          const interestPortion = principal * monthlyRate;
          if (payment <= interestPortion) {
            throw new Error(
              'Monthly payment is too low to cover the interest. Please increase the payment amount.'
            );
          }

          const calculatedTerm =
            (-Math.log(1 - (principal * monthlyRate) / payment) /
              Math.log(1 + monthlyRate)) /
            12;
          setResults({
            calculatedPrincipal: principal,
            calculatedPayment: payment,
            calculatedTerm,
          });
          setError(null);
        }
      } else {
        throw new Error(
          'Please provide at least three of the four variables (Loan Amount, Monthly Payment, Interest Rate, Term).'
        );
      }
    } catch (err) {
      setError(err.message);
      setResults({
        calculatedPrincipal: null,
        calculatedPayment: null,
        calculatedTerm: null,
      });
    }
  };

  return (
    <CalculatorContainer>
      <CalculatorTitle>Mortgage Note Calculator</CalculatorTitle>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem label="Loan Amount" name="principal">
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Enter Loan Amount"
          />
        </FormItem>
        <FormItem label="Interest Rate (%)" name="interestRate">
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            placeholder="Enter Interest Rate"
          />
        </FormItem>
        <FormItem label="Term (years)" name="term">
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/(,*)/g, '')}
            placeholder="Enter Term in Years"
          />
        </FormItem>
        <FormItem label="Monthly Payment" name="payment">
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Enter Monthly Payment"
          />
        </FormItem>
        <FormItem>
          <StyledButton type="primary" htmlType="submit">
            Calculate
          </StyledButton>
        </FormItem>
      </Form>
      {error && (
        <Alert message={error} type="error" style={{ marginBottom: '20px' }} />
      )}
      <ResultContainer>
        {results.calculatedPrincipal !== null && (
          <ResultItem>
            <strong>Loan Amount:</strong> $
            {results.calculatedPrincipal.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </ResultItem>
        )}
        {results.calculatedPayment !== null && (
          <ResultItem>
            <strong>Monthly Payment:</strong> $
            {results.calculatedPayment.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </ResultItem>
        )}
        {results.calculatedTerm !== null && (
          <ResultItem>
            <strong>Term:</strong>{' '}
            {results.calculatedTerm.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            years
          </ResultItem>
        )}
      </ResultContainer>
    </CalculatorContainer>
  );
};

export default MortgageNoteCalculator;
