import { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
// Hooks
import usePrevious from './usePrevious';

/**
 *
 * @param {Object} points Takes fetch data from MLS API to format for markers
 * @param {Object} polygon Current Drawn polygon if exists
 * @param {Object} geometry Google Maps Geometry library
 *
 * @return {Object} Returns formatted markers and markers contained in polygon
 */
const useMarkers = ({ points = [], geometry, polygon, state, bounds }) => {
  const [markers, setMarkers] = useState([]);
  const { ne, sw } = bounds || {};

  const polygonPaths =
    state.now.length &&
    state.now[0].geometry
      .getPath()
      .getArray()
      .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));

  const prevPolygonPaths = usePrevious(polygonPaths);

  useEffect(() => {
    console.log('useMarkers useEffect called');
    console.log('points:', points);
    console.log('ne:', ne, 'sw:', sw);
    console.log('polygon:', polygon);
    console.log('geometry:', geometry);

    if (polygon && geometry) {
      const polygonPath =
        polygon
          .getPath()
          .getArray()
          .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));

      if (!isEqual(polygonPath, prevPolygonPaths)) {
        const pointsWithinPolygon = points.filter((point) =>
          geometry.poly.containsLocation(point.position, polygon)
        );
        setMarkers(pointsWithinPolygon);
        console.log('Markers set to points within polygon:', pointsWithinPolygon);
      }
    } else if (ne && sw && ne.lat !== undefined && sw.lat !== undefined) {
      // No polygon, filter points within the bounds if bounds are available
      const markersInBounds = points.filter((point) => {
        const { lat, lng } = point.position;
        return (
          lat >= sw.lat &&
          lat <= ne.lat &&
          lng >= sw.lng &&
          lng <= ne.lng
        );
      });
      setMarkers(markersInBounds);
      console.log('Markers set to markers in bounds:', markersInBounds);
    } else {
      // No polygon and no bounds, set markers to all points
      setMarkers(points);
      console.log('Markers set to all points:', points);
    }
  }, [polygon, points, prevPolygonPaths, ne, sw, geometry]);

  return {
    markers,
  };
};

export default useMarkers;
