// NeighborhoodInfo.js
import React from 'react';
import {
  Container,
  Title,
  TableWrapper,
  TableInner,
  TableContent,
  InfoGrid,
  InfoItem,
  Label,
  Value
} from './styled';

const NeighborhoodInfo = ({ neighborhood }) => {
  const details = [
    {
      label: "Name",
      value: neighborhood.name || 'N/A'
    },
    {
      label: "Type",
      value: neighborhood.type || 'N/A'
    },
    {
      label: "HUD Area Code",
      value: neighborhood.hudAreaCode || 'N/A'
    },
    {
      label: "HUD Area Name",
      value: neighborhood.hudAreaName || 'N/A'
    }
  ];

  return (
    <Container>
      <Title>Neighborhood Information</Title>
      <TableWrapper>
        <TableInner>
          <TableContent>
            <InfoGrid>
              {details.map((item, index) => (
                <InfoItem 
                  key={item.label}
                  isEven={index % 2 === 0}
                  isLastRow={index >= details.length - 2}
                >
                  <Label>{item.label}</Label>
                  <Value>{item.value}</Value>
                </InfoItem>
              ))}
            </InfoGrid>
          </TableContent>
        </TableInner>
      </TableWrapper>
    </Container>
  );
};

export default NeighborhoodInfo;