const API_URL = process.env.REACT_APP_BACKEND_URL;

const fullUrl = (path) => {
  return `${API_URL}${path}`;
};

export const api = {
  auth: {
    signUp: fullUrl('/auth/signup'),
    signIn: fullUrl('/auth/signin'),
    clientSignIn: fullUrl('/auth/clientSignIn'),
    whoami: fullUrl('/auth/whoami'),
    agentSignIn: fullUrl('/auth/agentSignin'),
    clientSignUp: fullUrl('/auth/client-signup'),
    getProfile: fullUrl('/auth/get-profile-info'),
    updateProfile: fullUrl('/auth/update-profile-info'),
  },
  listing: {
    metadata: fullUrl('/listing/metadata'),
    propertySearch: fullUrl('/listing/propertySearch'),
    propertyDetail: fullUrl('/listing/propertyDetail'),
    propertyDetailBulk: fullUrl('/listing/propertyDetailBulk'),
    counts: fullUrl('/listing/counts'),
    propGPTSearch: fullUrl('/listing/propGPTSearch'),
    AutoComplete: fullUrl('/listing/AutoComplete'),
  },
  user:{
    setFavorite: fullUrl('/user/favorite'),       
    getFavorites: fullUrl('/user/get-favorites'),
    getAllUsers: fullUrl('/user/all-users'),
    approveUser: fullUrl('/user/approve'),
  },
  approvedEmails: {
    getAll: fullUrl('/user/approved-emails/all'),
    add: fullUrl('/user/approved-emails/add'),
    remove: fullUrl('/user/approved-emails/remove'),
  },
  investorProfile: {
    saveInvestmentPreferences: fullUrl('/auth/investor-profile/investment-preferences'),
    saveLocationPreferences: fullUrl('/auth/investor-profile/location-preferences'),
    saveFinancialInformation: fullUrl('/auth/investor-profile/financial-information'),
    getProfile: fullUrl('/auth/investor-profile/get-profile'),
  },
  lending: {
    setLendingTerms: fullUrl('/user/lending-terms'),
    deleteLendingTerms: fullUrl('/user/delete-lending-terms'),
    getLendedHomes: fullUrl('/user/get-lended-homes'),
    getLendedHomesWithOffers: fullUrl('/user/get-lended-homes-with-offers'),
    getAllLendedHomesPlusApplicants: fullUrl('/user/get-all-lended-homes-plus-offers'),
    getLendedHomesByLender: (lenderEmail) => fullUrl(`/user/get-lender-homes?email=${lenderEmail}`),
    getApplicants: (listingId) => fullUrl(`/user/get-applicants/${listingId}`), 
    getAllApplicants: fullUrl('/user/get-all-applicants'), 
  },
  buyers: {
    getLendedHomes: fullUrl('/user/get-lended-homes'),
    getTermsByListingId: (listingId) => fullUrl(`/user/get-terms-by-listing-id/${listingId}`),
  },
  application: {
    submit: fullUrl('/listing/application/submit'),
  },
};
