// mapStyles.js

const mapStyles = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [{ visibility: "on" }]
  },
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [{ visibility: "on" }]
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      { color: "#e6e8eb" }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      { color: "#ffd773" }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      { color: "#ffffff" }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      { color: "#a5d7e9" }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      { color: "#e8f0e8" }  // Light green color for parks
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels",
    stylers: [
      { visibility: "off" }  // Hide park labels
    ]
  },
];

export default mapStyles;