// SchoolsInfo.js
import React, { useState } from 'react';
import {
  Container,
  SchoolsList,
  SchoolCard,
  SchoolName,
  SchoolDetails,
  ExpandButton
} from './styled';

const SchoolsInfo = ({ schools }) => {
  const [showAll, setShowAll] = useState(false);
  const displayedSchools = showAll ? schools : schools.slice(0, 3);

  return (
    <Container>
      <SchoolsList>
        {displayedSchools.map((school, index) => (
          <SchoolCard key={index}>
            <SchoolName>{school.name}</SchoolName>
            <SchoolDetails>
              <p>Grades: {school.grades}</p>
              <p>Enrollment: {school.enrollment.toLocaleString()}</p>
            </SchoolDetails>
          </SchoolCard>
        ))}
      </SchoolsList>
      
      {schools.length > 3 && (
        <ExpandButton onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Show Less' : `See ${schools.length - 3} More Schools`}
        </ExpandButton>
      )}
    </Container>
  );
};

export default SchoolsInfo;