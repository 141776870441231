import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

// Utils
import { propertyFormat } from '../utils/propertyFormat';
import { getStreetViewImage } from '../utils/getStreetView';

// Constants
import { api } from '../constants/api';

const useMapGetPropertySearch = (initialParams) => {
  const [properties, setProperties] = useState([]);
  const [propertiesWithImages, setPropertiesWithImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [params, setParams] = useState(initialParams);

  const fetchProperties = useCallback(
    debounce(async (searchParams) => {
      try {
        setIsLoading(true);

        const response = await axios.post(api.listing.propertySearch, searchParams);

        if (!response.data.length) {
          console.log('No properties returned from API');
          setIsLoading(false);
          setProperties([]);
          return;
        }

        const data = propertyFormat(response.data);
        setProperties(data);
      } catch (error) {
        console.error('API call failed:', error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  // Fetch property data
  useEffect(() => {
    const {
      // Location parameters
      latitude,
      longitude,
      radius,
      mapBounds,
      
      // Property parameters
      property_type,
      propertyTypes,
      size,
      view,
      
      // Price parameters
      price_min,
      price_max,
      mls_listing_price_max,
      
      // Room parameters
      bedrooms_min,
      bedrooms_max,
      bathrooms_min,
      bathrooms_max,
      
      // Status parameters
      listing_status,
      days_on_market,
      
      // Boolean filters
      hide_pending,
      hide_foreclosures,
      hide_55_plus,
      open_house,
      virtual_tours,
      three_d_tours
    } = params;

    if (
      !latitude &&
      !longitude &&
      !mapBounds
    ) {
      console.log('No location parameters provided, skipping API call');
      return;
    }

    const apiParams = {
      // Location
      latitude,
      longitude,
      radius,
      ...mapBounds,

      // Property
      property_type,
      propertyTypes,
      size,
      view,

      // Price
      ...(price_min !== undefined && { price_min }),
      ...(price_max !== undefined && { price_max }),
      ...(mls_listing_price_max !== undefined && { mls_listing_price_max }),

      // Rooms
      ...(bedrooms_min !== undefined && { bedrooms_min }),
      ...(bedrooms_max !== undefined && { bedrooms_max }),
      ...(bathrooms_min !== undefined && { bathrooms_min }),
      ...(bathrooms_max !== undefined && { bathrooms_max }),

      // Status
      ...(listing_status && listing_status !== 'Any - for sale' && { listing_status }),
      ...(days_on_market && days_on_market !== 'Any' && { days_on_market: parseInt(days_on_market) }),

      // Boolean filters
      ...(hide_pending && { hide_pending }),
      ...(hide_foreclosures && { hide_foreclosures }),
      ...(hide_55_plus && { hide_55_plus }),
      ...(open_house && { open_house }),
      ...(virtual_tours && { virtual_tours }),
      ...(three_d_tours && { three_d_tours })
    };

    // Remove undefined values
    const cleanParams = Object.fromEntries(
      Object.entries(apiParams).filter(([_, value]) => value !== undefined)
    );

    console.log('Sending API request with params:', cleanParams);
    console.log('property_type:', cleanParams.property_type);

    fetchProperties(cleanParams);
  }, [params, fetchProperties]);

  // Fetch Street View images
  useEffect(() => {
    const fetchStreetViewImages = async () => {
      const propertiesWithImages = await Promise.all(
        properties.map(async (property) => {
          const streetViewImage = await getStreetViewImage({
            lat: property.position.lat,
            lng: property.position.lng,
          });

          return {
            ...property,
            streetViewImage,
          };
        })
      );

      setPropertiesWithImages(propertiesWithImages);
    };

    if (properties.length > 0) {
      fetchStreetViewImages();
    } else {
      console.log('No properties available, skipping Street View image fetch');
    }
  }, [properties]);

  const updateSearchParams = useCallback((newParams) => {
    setParams(prevParams => ({ ...prevParams, ...newParams }));
  }, []);

  return { properties: propertiesWithImages, isLoading, hasError, updateSearchParams };
};

export default useMapGetPropertySearch;