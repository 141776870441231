import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyCheck,
  faHouse,
  faHeart,
  faCalculator,
  faGear,
  faUser,
  faMap
} from '@fortawesome/free-solid-svg-icons';
import {
  LogoDarkSvg,
} from '../../../../components/Icons';
import { ROUTE_PATH } from '../../../../constants/routePath';
import { Flex, Menu, Dropdown } from 'antd';
import { useStore } from "../../../../hooks/storeContext";
import styles from './HeaderMenu.module.scss';

const navList = [
  [
    { title: 'Dashboard', icon: faHouse, route: ROUTE_PATH.DASHBOARD },
    { title: 'Map Search', icon: faMap, route: ROUTE_PATH.MAP },
    { title: 'My Properties', icon: faMoneyCheck, route: ROUTE_PATH.MY_PROPERTIES },
    { title: 'Favorited', icon: faHeart, route: ROUTE_PATH.FAVORITES },
    { title: 'Calculators', icon: faCalculator, route: ROUTE_PATH.CALCULATORS },
  ],
];

const HeaderMenu = ({ onClose, onItemClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = useStore();

  const onClickLink = (path) => {
    navigate(path);
    onItemClick(); // Close the menu when an item is clicked
  };

  const handleMenuClick = ({ key }) => {
    if (key === 'signOut') {
      authStore.logout();
      onItemClick(); // Close the menu after logout
    } else if (key === 'Profile' || key === 'My Account') {
      navigate(ROUTE_PATH.SETTINGS, { state: { activeTab: key } });
      onItemClick(); // Close the menu after navigation
    }
  };

  const userMenuItems = [
    {
      label: 'Profile',
      key: 'Profile',
    },
    {
      label: 'My Account',
      key: 'My Account',
    },
    {
      type: 'divider',
    },
    {
      label: 'Sign out',
      key: 'signOut',
    },
  ];

  const userMenu = (
    <Menu onClick={handleMenuClick} items={userMenuItems} />
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Flex gap={12} align="center">
          <Icon
            component={LogoDarkSvg}
            onClick={() => onClickLink(ROUTE_PATH.DASHBOARD)}
          />
          <span className={styles.logo}>Invested</span>
        </Flex>

        <CloseOutlined className={styles.iconX} onClick={onClose} />
      </div>
      <div className={styles.content}>
        <Flex vertical gap={16}>
          {navList[0].map((tab) => (
            <Flex
              gap={12}
              key={tab.title}
              className={
                location.pathname === tab.route ? styles.active : styles.tab
              }
              onClick={() => onClickLink(tab.route)}
            >
              <FontAwesomeIcon icon={tab.icon} />
              <span>{tab.title}</span>
            </Flex>
          ))}
        </Flex>

        <Flex vertical gap={16}>
          <Dropdown overlay={userMenu} trigger={['click']} placement="topLeft">
            <Flex
              gap={12}
              className={styles.tab}
            >
              <FontAwesomeIcon icon={faUser} />
              <span>User Menu</span>
            </Flex>
          </Dropdown>
          {navList[1] && navList[1].map((tab) => (
            <Flex
              gap={12}
              key={tab.title}
              className={
                location.pathname === tab.route ? styles.active : styles.tab
              }
              onClick={() => onClickLink(tab.route)}
            >
              <FontAwesomeIcon icon={tab.icon} />
              <span>{tab.title}</span>
            </Flex>
          ))}
        </Flex>
      </div>
    </div>
  );
};

export default HeaderMenu;