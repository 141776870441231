import React, { useState } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserOutlined, HeartOutlined, DownOutlined } from '@ant-design/icons';
import {
  StyledRow,
  StyledHeader,
  StyledCol,
  ContentWrapper,
  StyledMenu,
  RightMenu,
  StyledButton,
  LogoContainer,
  DesktopMenu,
  MobileHeader
} from './styled';
import { Image } from 'antd';
import { Outlet } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks/storeContext';
import Footer from './components/Footer';
import MobileMenu from './components/MobileMenu';
import LoginModal from './components/LoginModal';
import SignupModal from './components/SignupModal';
import { isDesktop, isMobile } from 'react-device-detect';

const { SubMenu } = Menu;

const UserMenu = ({ onSignOut, isLoggedIn, onLoginClick, onSignupClick }) => {
  const loggedInMenu = (
    <Menu>
      <Menu.Item key="buyerProfile">
        <Link to="/buyer-profile">Buyer profile</Link>
      </Menu.Item>
      <Menu.Item key="buyerProfile">
        <Link to="/saved-homes">Saved Listings</Link>
      </Menu.Item>
      <Menu.Item key="settings">
        <Link to="/settings">Settings</Link>
      </Menu.Item>
      <Menu.Item key="signOut" onClick={onSignOut}>
        Sign out
      </Menu.Item>
    </Menu>
  );

  const loggedOutMenu = (
    <Menu>
      <Menu.Item key="login" onClick={onLoginClick}>
        Log in
      </Menu.Item>
      <Menu.Item key="signup" onClick={onSignupClick}>
        Sign up
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {isDesktop && isLoggedIn && (
        <Button icon={<HeartOutlined />} style={{ marginRight: '10px' }} />
      )}
      <Dropdown overlay={isLoggedIn ? loggedInMenu : loggedOutMenu} placement="bottomRight">
        <Button type="primary">
          <UserOutlined />
          <DownOutlined style={{ fontSize: '10px', marginLeft: '4px' }} />
        </Button>

      </Dropdown>
    </div>
  );
};

const PublicLayout = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loginVisible, setLoginVisible] = useState(false);
  const [signupVisible, setSignupVisible] = useState(false);
  const { authStore } = useStore();

  const handleSignOut = () => {
    authStore.logout();
    navigate('/');
  };

  const handleLoginClick = () => {
    setLoginVisible(true);
  };

  const handleSignupClick = () => {
    setSignupVisible(true);
  };

  return (
    <StyledRow>
      <StyledCol span={24}>
        <StyledHeader>
          {isMobile ? (
            <MobileHeader>
              <MobileMenu />
              <Link to="/">
                <LogoContainer>
                  <Image preview={false} src={logo} alt="Logo" style={{ width: '30px' }} />
                  <span>Invested.homes</span>
                </LogoContainer>
              </Link>
              <UserMenu
                isLoggedIn={authStore.isLoggedIn}
                onSignOut={handleSignOut}
                onLoginClick={handleLoginClick}
                onSignupClick={handleSignupClick}
              />
            </MobileHeader>
          ) : (
            <DesktopMenu>
              <Link to="/">
                <LogoContainer>
                  <Image preview={false} src={logo} alt="Logo" style={{ width: '30px' }} />
                  <span>Invested.homes</span>
                </LogoContainer>
              </Link>
              <StyledMenu mode="horizontal" selectedKeys={[location.pathname]}>
                <Menu.Item key="/buy">
                  <Link style={{ fontSize: '16px' }} to="/">Buy</Link>
                </Menu.Item>
                <SubMenu title="Mortgage">
                  <Menu.Item key="/tools/mortgage-calculator">
                    <Link to="/tools/mortgage-calculator">Mortgage calculator</Link>
                  </Menu.Item>
                  <Menu.Item key="/tools/affordability-calculator">
                    <Link to="/tools/affordability-calculator">Affordability Calculator</Link>
                  </Menu.Item>
                </SubMenu>
              </StyledMenu>
              <RightMenu>
                {authStore.isLoggedIn ? (
                  <UserMenu
                    isLoggedIn={true}
                    onSignOut={handleSignOut}
                  />
                ) : (
                  <>
                    <StyledButton type="text" onClick={handleLoginClick}>Log in</StyledButton>
                    <StyledButton type="primary" onClick={handleSignupClick}>Sign up</StyledButton>
                  </>
                )}
              </RightMenu>
            </DesktopMenu>
          )}
        </StyledHeader>
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
        
      </StyledCol>
      <LoginModal visible={loginVisible} onClose={() => setLoginVisible(false)} />
      <SignupModal visible={signupVisible} onClose={() => setSignupVisible(false)} />
    </StyledRow>
  );
});

export default PublicLayout;