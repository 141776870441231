import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Image } from 'antd';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import { Button, Flex } from 'antd';
import BaseTypography from '../../../../components/BaseTypography';
import { BedroomSvg, BathroomSvg } from '../../../../components/Icons';
import { ROUTE_PATH } from '../../../../constants/routePath';
import { getStreetViewImage } from '../../../../utils/getStreetView';
import { formatNumberToDollarsAbbreviated } from '../../../../utils/formatNumber';

// Styled Components
export const InfoWindowContainer = styled.div`
  width: 296px;
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const Address = styled(BaseTypography)`
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  flex: 1;
`;

export const ImageContainer = styled.div`
  height: 120px;
  display: flex;
`;

export const Content = styled.div`
  padding: 10px;
  text-align: left!important;
`;

export const CityState = styled(BaseTypography)`
  opacity: 0.5;
  text-align: left!important;
`;

export const SubHeader = styled(BaseTypography)`
  opacity: 0.5;
  text-align: left!important;
`;

export const LandUse = styled.span`
  font-weight: 600;
  opacity: 0.5;
`;

export const Actions = styled.div`
  padding: 5px 0;
`;

export const ActionButton = styled(Button)`
  color: #005eb8;
  opacity: 1;
  text-decoration: underline !important;
  padding: 0;
  margin-right: 10px;
  font-weight: 800;
`;

export const PropertiesInfo = styled(Flex)`
  padding-top: 10px;
  gap: 10px;
`;

export const ListingProperties = styled.div`
  display: inline-block;
  font-weight: 500;
  text-align: left!important;
  margin-right: 15px;

  .value {
    opacity: 0.7;
    margin-left: 8px;
    font-size: 14px;
  }

  .sqft {
    opacity: 0.7;
    margin-left: 5px;
    font-size: 12px;
  }
`;

export const Break = styled.hr`
  border: none;
  border-top: 1px solid #e8e8e8;
  margin: 5px 0;
`;

export const Flx = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: ${(props) => props.align || 'stretch'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  gap: ${(props) => props.gap || '0'};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
`;

const Markers = ({ point, onClick, isSelected, clusterer }) => {
  const navigate = useNavigate();
  const [streetViewImage, setStreetViewImage] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }, []);

  useEffect(() => {
    if (isSelected && point?.position?.lat && point?.position?.lng) {
      const fetchImage = async () => {
        try {
          const image = await getStreetViewImage({
            lat: point.position.lat,
            lng: point.position.lng
          });
          setStreetViewImage(image);
        } catch (error) {
          console.error('Error fetching Street View image:', error);
        }
      };
      fetchImage();
    }
  }, [isSelected, point?.position]);

  const isValidPosition =
    point &&
    point.position &&
    typeof point.position.lat === 'number' &&
    typeof point.position.lng === 'number';

  if (!isValidPosition) {
    console.warn('Invalid marker position:', point);
    return null;
  }

  const { property } = point;
  const formattedLastSaleDate = formatDate(property.last_sale_date);

  const handleMarkerClick = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    onClick();
  };

  const handleListingClick = (e) => {
    e.stopPropagation();
    navigate(`${ROUTE_PATH.DETAILS}/${property.id}`);
  };

  const shouldShowPropertyInfo = () => {
    return (property.bedrooms > 0 || property.bathrooms > 0);
  };

  return (
    <Marker
      position={point.position}
      onClick={handleMarkerClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      clusterer={clusterer} // Add the clusterer prop
      options={{
        optimized: true // Enable marker optimization
      }}
      icon={{
        path: 'M -30,-15 h 60 a 5,5 0 0 1 5,5 v 15 a 5,5 0 0 1 -5,5 h -25 l -5,8 l -5,-8 h -25 a 5,5 0 0 1 -5,-5 v -15 a 5,5 0 0 1 5,-5',
        fillColor: isHovered ? '#005eb8' : '#FFFFFF',
        fillOpacity: 1,
        strokeColor: '#005eb8',
        strokeWeight: 1.5,
        scale: 0.85,
        labelOrigin: new window.google.maps.Point(0, -5),
      }}
      label={{
        text: formatNumberToDollarsAbbreviated(property.current_price),
        color: isHovered ? '#FFFFFF' : '#005eb8',
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Arial, sans-serif',
      }}
      zIndex={isSelected ? 999 : undefined} // Ensure selected marker appears on top
    >
      {isSelected && (
        <InfoWindow
          position={point.position}
          onCloseClick={() => onClick()}
          options={{
            pixelOffset: new window.google.maps.Size(0, -15)
          }}
        >
          <InfoWindowContainer>
            <Header>
              <Address>{property.address}</Address>
            </Header>
            <ImageContainer>
              {streetViewImage && (
                <Image
                  height="100%"
                  width="100%"
                  preview={false}
                  src={streetViewImage}
                />
              )}
            </ImageContainer>
            <Content>
              <Flx gap="5px" direction="column">
                {property.neighborhood || property.type}
                <CityState fontSize="14px">
                  {property.city}, {property.state}
                </CityState>
                <Break />
                <SubHeader fontSize="14px">
                  <span style={{ fontWeight: '700' }}>Land Use: </span>
                  <LandUse>{property.type}</LandUse>
                </SubHeader>
                <SubHeader fontSize="14px">
                  <span style={{ fontWeight: '700' }}>Last Sale Date: </span>
                  {formattedLastSaleDate}
                </SubHeader>
              </Flx>

              <Actions>
                <ActionButton
                  type="link"
                  size="small"
                  onClick={handleListingClick}
                >
                  <span style={{ textDecoration: 'underline' }}>Listing</span>
                </ActionButton>
                {/* <ActionButton
                  type="link"
                  size="small"
                >
                  <span>Tax</span>
                </ActionButton> */}
              </Actions>

              {shouldShowPropertyInfo() && (
                <>
                  <Break />
                  <PropertiesInfo>
                    {property.bedrooms > 0 && (
                      <ListingProperties>
                        <Icon component={BedroomSvg} />
                        <span className="value">{property.bedrooms}</span>
                      </ListingProperties>
                    )}
                    {property.bathrooms > 0 && (
                      <ListingProperties>
                        <Icon component={BathroomSvg} />
                        <span className="value">{property.bathrooms}</span>
                      </ListingProperties>
                    )}
                  </PropertiesInfo>
                </>
              )}
            </Content>
          </InfoWindowContainer>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default Markers;