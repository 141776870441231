// GeneralInfo.js
import React from 'react';
import {
  Container,
  Title,
  TableWrapper,
  TableInner,
  TableContent,
  InfoGrid,
  InfoItem,
  Label,
  Value
} from './styled';

const GeneralInfo = ({ propertyDetail }) => {
  const { propertyInfo, lotInfo, floodZoneDescription } = propertyDetail;

  const details = [
    { label: "Bedrooms", value: propertyInfo.bedrooms || 'N/A' },
    { label: "Bathrooms", value: propertyInfo.bathrooms || 'N/A' },
    { label: "Square Feet", value: propertyInfo.buildingSquareFeet 
      ? `${propertyInfo.buildingSquareFeet.toLocaleString()} sqft` 
      : 'N/A' },
    { label: "Lot Size", value: lotInfo.lotAcres ? `${lotInfo.lotAcres} acres` : 'N/A' },
    { label: "Year Built", value: propertyInfo.yearBuilt || 'N/A' },
    { label: "Stories", value: propertyInfo.stories || 'N/A' },
    { label: "Fireplace", value: propertyInfo.fireplace ? 'Yes' : 'No' },
    { label: "Pool", value: propertyInfo.pool ? 'Yes' : 'No' },
    { label: "Air Conditioning", value: propertyInfo.airConditioningType || 'N/A' },
    { label: "Heating", value: propertyInfo.heatingType || 'N/A' },
    { label: "Construction", value: propertyInfo.construction || 'N/A' },
    { label: "Roof Material", value: propertyInfo.roofMaterial || 'N/A' },
    { label: "Flood Zone", value: floodZoneDescription || 'N/A' },
    { label: "Land Use", value: lotInfo.landUse || 'N/A' },
    { label: "Zoning", value: lotInfo.zoning || 'N/A' },
    { label: "Subdivision", value: lotInfo.subdivision || 'N/A' }
  ];

  return (
    <Container>
      <Title>General Information</Title>
      <TableWrapper>
        <TableInner>
          <TableContent>
            <InfoGrid>
              {details.map((item, index) => (
                <InfoItem 
                  key={item.label}
                  isEven={index % 2 === 0}
                  isLastRow={index >= details.length - 2}
                >
                  <Label>{item.label}</Label>
                  <Value>{item.value}</Value>
                </InfoItem>
              ))}
            </InfoGrid>
          </TableContent>
        </TableInner>
      </TableWrapper>
    </Container>
  );
};

export default GeneralInfo;