import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Space, Flex, Button, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faHeart, faCog, faHome, faMoneyCheck, faUser, faMap } from '@fortawesome/free-solid-svg-icons';
import { useStore } from "../../../hooks/storeContext";

// Styles
import styles from './SideNavigation.module.scss';

// Constants
import { ROUTE_PATH } from '../../../constants/routePath';

// Custom Icons
import { LogoDarkSvg } from '../../../components/Icons';

const SideNavigation = () => {
  const { Sider } = Layout;
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = useStore();

  const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#000929',
    backgroundColor: '#FFFFFF',
    borderRight: '1px solid #DEE8F7',
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100vh',
    zIndex: '10',
  };

  const activeStyle = {
    backgroundColor: '#005eb8',
    color: 'white',
  };

  const buttonStyle = (isActive) => ({
    ...(isActive ? activeStyle : {}),
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const iconStyle = (isActive) => ({
    color: isActive ? 'white' : '#999da9',
  });

  const isDashboard = location.pathname === '/';
  const isDetails = location.pathname.includes('details');
  const isMap = location.pathname.includes('map') || location.pathname.includes('search');
  const isFavorites = location.pathname.includes('favorites');
  const isSettings = location.pathname.includes('settings');
  const isMyProperties = location.pathname.includes('myproperties');
  const isCalculators = location.pathname.includes('calculators');

  const handleClickDashboard = () => navigate(ROUTE_PATH.DASHBOARD);
  const handleClickMap = () => navigate(ROUTE_PATH.MAP);
  const handleClickUserFavorites = () => navigate(ROUTE_PATH.FAVORITES);
  const handleClickSettings = () => navigate(ROUTE_PATH.SETTINGS);
  const handleClickMyProperties = () => navigate(ROUTE_PATH.MY_PROPERTIES);
  const handleClickCalculators = () => navigate(ROUTE_PATH.CALCULATORS);

  const handleMenuClick = (e) => {
    if (e.key === 'signOut') {
      authStore.logout();
    } else if (e.key === 'Profile' || e.key === 'My Account') {
      navigate(ROUTE_PATH.SETTINGS, { state: { activeTab: e.key } });
    }
  };

  const items = [
    {
      label: 'Profile',
      key: 'Profile',
    },
    {
      label: 'My Account',
      key: 'My Account',
    },
    {
      type: 'divider',
    },
    {
      label: 'Sign out',
      key: 'signOut',
    },
  ];

  if (isDetails) return null;

  return (
    <div style={{ width: '88px' }}>
      <Sider width="88px" style={siderStyle}>
        <Space
          className={styles.logo}
          onClick={handleClickDashboard}
          align="start"
        >
          <LogoDarkSvg />
        </Space>

        <Flex align="center" gap={36} vertical>
          <Button
            onClick={handleClickDashboard}
            style={buttonStyle(isDashboard)}
            icon={<FontAwesomeIcon icon={faHome} style={iconStyle(isDashboard)} />}
          />
          <Button
            onClick={handleClickMap}
            style={buttonStyle(isMap)}
            icon={<FontAwesomeIcon icon={faMap} style={iconStyle(isMap)} />}
          />
          <Button
            onClick={handleClickMyProperties}
            style={buttonStyle(isMyProperties)}
            icon={<FontAwesomeIcon icon={faMoneyCheck} style={iconStyle(isMyProperties)} />}
          />
          <Button
            onClick={handleClickUserFavorites}
            style={buttonStyle(isFavorites)}
            icon={<FontAwesomeIcon icon={faHeart} style={iconStyle(isFavorites)} />}
          />
          <Button 
            onClick={handleClickCalculators}
            style={buttonStyle(isCalculators)}
            icon={<FontAwesomeIcon icon={faCalculator} style={iconStyle(isCalculators)} />}
          />
        </Flex>

        <Flex
          className={styles.settingsMenuContainer}
          align="center"
          gap={36}
          vertical
        >
          <Dropdown menu={{ items, onClick: handleMenuClick }} placement="rightTop" trigger={['click']}>
            <Button
              style={buttonStyle(false)}
              icon={<FontAwesomeIcon icon={faUser} style={iconStyle(false)} />}
            />
          </Dropdown>
          {/* <Button
            onClick={handleClickSettings}
            style={buttonStyle(isSettings)}
            icon={<FontAwesomeIcon icon={faCog} style={iconStyle(isSettings)} />}
          /> */}
        </Flex>
      </Sider>
    </div>
  );
};

export default SideNavigation;