import React, { useState, useEffect } from 'react';
import {
  Container,
  Header,
  Subheader,
  Divider,
  BackgroundImageContainer
} from './styled';
import headerImage from '../../../../assets/images/hero-afford-invested.jpg';
import CalculatorSection from './CalculatorSection';
import FAQSection from './FAQSection';
import CommonTerms from './CommonTerms';
import Footer from '../../../../layouts/PublicLayout/components/Footer';

const AffordabilityCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <BackgroundImageContainer imageUrl={headerImage} />
      <Container style={{ position: 'relative', zIndex: 1, marginTop: '-225px' }}>
        <Header>Know how much you can afford</Header>
        <Subheader>We&apos;ll help you estimate how much you can afford to spend on a home.</Subheader>
        <CalculatorSection />
        <Divider thickness="1px" color="#cccccc" spacing="60px 0" />
        <FAQSection />
        <Divider thickness="1px" color="#cccccc" spacing="60px 0" />
        <CommonTerms />
      </Container>
      <Footer />
    </div>
  );
};

export default AffordabilityCalculator;