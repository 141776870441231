import React, { useState } from 'react';
import { Row, Col, Input } from 'antd';
import Search from './Search';
import BaseTypography from '../../components/BaseTypography';
import { useNavigate } from 'react-router-dom';
import MarketWatch from './MarketWatch';
import MyProperties from './MyProperties';
import { isMobile } from 'react-device-detect';
import { useStore } from "../../hooks/storeContext";
import Icon from '@ant-design/icons';
import { SearchIconSvg } from '../../components/Icons';
import SavedSearches from './SavedSearches';
import { createSearchParams } from 'react-router-dom';
import styles from './Dashboard.module.scss';

import {
  ContentContainer,
  SearchField,
  StyledCard,
} from './Styled';

import { ROUTE_PATH } from '../../constants/routePath';

const Dashboard = () => {
  const { authStore } = useStore();
  const firstName = authStore.me.fullName.split(' ')[0];
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchQuery.trim()) {
      const searchParams = createSearchParams({ streetName: searchQuery }).toString();
      const path = {
        pathname: ROUTE_PATH.SEARCH,
        search: searchParams,
      };
      navigate(path);
    }
  };

  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: '0px' }}>
      <ContentContainer>
        {/* <Row gutter={10} justify="space-between" align="middle">
          {isMobile && (
            <Col span={24}>
              <SearchField>
                <Input
                  size="large"
                  margin="auto!important"
                  placeholder="Search for properties..."
                  prefix={<Icon component={SearchIconSvg} />}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onPressEnter={handleSearch}
                />
              </SearchField>
            </Col>
          )}
        </Row> */}
        <p style={{ fontSize: '20px', marginBottom: '30px', fontWeight: '700' }}>Hi {firstName}, Start exploring properties to invest in.</p>
        <Row gutter={20}>
          <Col xs={24} xl={12}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <BaseTypography
                  className={styles.cardTitle}
                  fontSize="20px"
                  align="left"
                >
                  Search
                </BaseTypography>
                <StyledCard>
                  <Search />
                </StyledCard>
              </Col>
              <Col span={24}>
                <BaseTypography className={styles.cardTitle} fontSize="22px" align="left">
                  Saved Searches
                </BaseTypography>
                <StyledCard>
                  <SavedSearches />
                </StyledCard>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={12}>
          <Row gutter={[20, 20]}>
              <Col span={24}>
                <BaseTypography className={styles.cardTitle} type="text" fontSize="22px" align="left">
                  My Properties
                </BaseTypography>
                <StyledCard>
                  <MyProperties />
                </StyledCard>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <BaseTypography className={styles.cardTitle} type="text" fontSize="22px" align="left">
                  Market Watch
                </BaseTypography>
                <StyledCard>
                  <MarketWatch />
                </StyledCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentContainer>
    </div>
  );
};

export default Dashboard;