// propertyFormat.js
export const propertyFormat = (propertyData) => {
  return propertyData.reduce((properties, property) => {
    properties.push({
      // Identification
      id: property.id,

      // Address information
      address: property.address?.street ?? null,
      city: property.address?.city ?? null,
      state: property.address?.state ?? null,
      zip: property.address?.zip ?? null,
      county: property.address?.county ?? null,
      full_address: property.address?.address ?? null,

      // Property characteristics
      lot_sqft: property.squareFeet ?? 0,
      acres: property.listhub?.LotSizeAcres ?? null,
      bedrooms: property.bedrooms ?? 0,
      bathrooms: property.bathrooms ?? 0,
      type: property.propertyUse ?? null,

      // Location details
      subdivision: property.neighborhood?.type === 'subdivision' ? property.neighborhood.name : null,
      neighborhood: property.neighborhood?.name ?? null,
      position: {
        lat: property.latitude ?? null,
        lng: property.longitude ?? null,
      },

      // Listing information
      current_price: property.mlsListingPrice ?? null,
      change_type: property.mlsStatus ?? null,
      dom: property.mlsDaysOnMarket ?? null,
      list_office: property.listhub?.ListOfficeName ?? null,

      // Property features
      absentee_owner: property.absenteeOwner ?? false,
      adjustable_rate: property.adjustableRate ?? false,
      air_conditioning_available: property.airConditioningAvailable ?? false,

      // Dates
      last_sale_date: property.lastSaleDate ?? null,

      // Additional fields (add all that are available in the data)
      year_built: property.yearBuilt ?? null,
      heating_type: property.heatingType ?? null,
      cooling_type: property.coolingType ?? null,
      garage_spaces: property.garageSpaces ?? 0,
      parking_type: property.parkingType ?? null,
      lot_size: property.lotSize ?? null,
      property_style: property.propertyStyle ?? null,
      roof_type: property.roofType ?? null,
      foundation_type: property.foundationType ?? null,
      exterior_wall_type: property.exteriorWallType ?? null,
      pool: property.hasPool ?? false,
      spa: property.hasSpa ?? false,
      view: property.hasView ?? false,
      waterfront: property.isWaterfront ?? false,
      fireplace: property.hasFireplace ?? false,
      basement: property.hasBasement ?? false,
      
      // Financial information
      tax_year: property.taxYear ?? null,
      annual_tax_amount: property.annualTaxAmount ?? null,
      hoa_fee: property.hoaFee ?? null,
      zoning: property.zoning ?? null,
      
      // Listing details
      listing_date: property.listingDate ?? null,
      original_list_price: property.originalListPrice ?? null,
      days_on_market: property.daysOnMarket ?? null,
      mls_number: property.mlsNumber ?? null,
      
      // Agent and office information
      listing_agent: property.listingAgent ?? null,
      listing_office: property.listingOffice ?? null,
      
      // Additional details
      virtual_tour_url: property.virtualTourUrl ?? null,
      description: property.description ?? null,
    });
    return properties;
  }, []);
};

// propertyDetailFormat.js
export const propertyDetailFormat = (propertyData) => {
  const {
    id,
    propertyInfo = {},
    lotInfo = {},
    ownerInfo = {},
    taxInfo = {},
    mlsHistory = [],
    schools = [],
    saleHistory = [],
    neighborhood = {},
    estimatedValue = null,
    estimatedEquity = null,
    estimatedMortgageBalance = null,
    equityPercent = null,
    floodZoneDescription = null,
    floodZoneType = null,
    estimatedMortgagePayment = null,
    mlsListingPrice = null,
    mlsStatus = null,
    mlsDaysOnMarket = null,
    propertyType = null,
    propertyUse = null,
    mlsListingDate = null,
    mlsHasPhotos = null,
    mlsKeywords = {},
    mlsTotalUpdates = null,
    lastSaleDate = null,
    lastSalePrice = null,
    mortgageHistory = [],
    foreclosureInfo = [],
    saleAmount = null,
    lastUpdateDate = null,
    equity = null,
    currentMortgages = [],
    openMortgageBalance = null,
    estimatedEquityPercent = null,
    lien = null,
    judgment = null,
    taxLien = null,
  } = propertyData;

  const formattedMlsHistory = mlsHistory.map((item) => ({
    listingDate: item.listingDate,
    status: item.status,
    listingPrice: item.listingPrice,
    agentName: item.agentName,
    agentOffice: item.agentOffice,
    agentEmail: item.agentEmail,
    agentPhone: item.agentPhone,
    baths: item.baths,
    beds: item.beds,
    squareFeet: item.squareFeet,
    statusDate: item.statusDate,
  }));

  const formattedSchools = schools.map((school) => ({
    name: school.name,
    city: school.city,
    enrollment: school.enrollment,
    grades: school.grades,
    levels: school.levels,
    location: school.location,
    state: school.state,
    zip: school.zip,
  }));

  const formattedSaleHistory = saleHistory.map((sale) => ({
    saleDate: sale.saleDate,
    saleAmount: sale.saleAmount,
    buyerNames: sale.buyerNames,
    sellerNames: sale.sellerNames,
    documentType: sale.documentType,
    documentNumber: sale.documentNumber,
    armsLength: sale.armsLength,
  }));

  const formattedPropertyDetail = {
    id,
    estimatedValue,
    estimatedEquity,
    estimatedMortgageBalance,
    equityPercent,
    estimatedMortgagePayment,
    lastUpdateDate,
    propertyType,
    propertyUse,
    floodZoneDescription,
    floodZoneType,
    mlsListingPrice,
    mlsStatus,
    mlsDaysOnMarket,
    mlsListingDate,
    mlsHasPhotos,
    mlsKeywords,
    mlsTotalUpdates,
    lastSaleDate,
    lastSalePrice,
    equity,
    openMortgageBalance,
    estimatedEquityPercent,
    lien,
    judgment,
    taxLien,

    propertyInfo: {
      ...propertyInfo,
      latitude: parseFloat(propertyInfo.latitude ?? 0),
      longitude: parseFloat(propertyInfo.longitude ?? 0),
    },
    lotInfo: { ...lotInfo },
    ownerInfo: { ...ownerInfo },
    taxInfo: { ...taxInfo },
    neighborhood: { ...neighborhood },

    mlsHistory: formattedMlsHistory,
    schools: formattedSchools,
    saleHistory: formattedSaleHistory,
    mortgageHistory,
    foreclosureInfo,
    currentMortgages,

    position: {
      lat: parseFloat(propertyInfo.latitude ?? 0),
      lng: parseFloat(propertyInfo.longitude ?? 0),
    },
  };

  return formattedPropertyDetail;
};
