// src/hooks/useAutocomplete.js

import { useState, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { api } from '../constants/api';

const useAutocomplete = () => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Debounce the API call to prevent excessive requests
  const fetchSuggestions = useCallback(
    debounce(async (value, searchType) => {
      if (value.length < 3) {
        setOptions([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const response = await axios.post(api.listing.AutoComplete, {
          search: value,
          search_types: [searchType], // Only 'A' and 'C'
        }, {
          headers: {
            'x-user-id': 'UniqueUserIdentifier', // Replace with actual user ID if needed
          },
        });

        // Ensure the response structure is as expected
        if (response.data && Array.isArray(response.data.data)) {
          // **Filter to only include 'TX' state**
          const filteredData = response.data.data.filter(item => item.state === 'TX');

          const suggestions = filteredData.map((item) => ({
            value: item.title,
            label: item.title,
            searchType: item.searchType,
            ...item,
          }));
          setOptions(suggestions);
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error('Error fetching autocomplete suggestions:', error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }, 300), // 300ms debounce
    []
  );

  const handleSearch = (value, searchType) => {
    fetchSuggestions(value, searchType);
  };

  return { options, handleSearch, loading };
};

export default useAutocomplete;
