// HomeDetails.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, Marker } from '@react-google-maps/api'; // Updated import

// Styles
import styles from './Details.module.scss';

// Constants
import { DETAILS_TABLE_COLUMNS } from '../../constants';
import { COLORS } from '../../constants/colors';
import { api } from '../../constants/api';

// Utils
import { formatNumberToDollars } from '../../utils/numberFormat';
import { getStreetViewImage } from '../../utils/getStreetView';

// Hooks
import useGetPropertyDetail from '../../hooks/useGetPropertyDetail';

// Components
import { Button, Col, Row, Skeleton, Card } from 'antd';
import Icon, { LeftOutlined } from '@ant-design/icons';
import BaseTypography from '../../components/BaseTypography';
import Specifications from './Specifications';
import ListingGallery from './ListingGallery';
import {
  LendSvg,
  PropertyDetailsSvg,
} from '../../components/Icons';
import { isMobile } from 'react-device-detect';
import { BaseButton } from '../../components/Button';
import ButtonsGroup from './ButtonsGroup';
import DescriptionDetails from './DescriptionDetails';
import Footer from '../../layouts/PublicLayout/components/Footer';

// Define map container style
const mapContainerStyle = {
  width: '100%',
  height: '400px', // Adjust height as needed
};

// Define a default center in case propertyDetail.position is unavailable
const defaultCenter = { lat: 37.7749, lng: -122.4194 }; // Example: San Francisco

// SVG to Data URL for custom marker icon
const svgIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="48" height="56" viewBox="0 0 48 56" fill="none">
  <!-- Main rectangle with rounded corners -->
  <path d="M8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H29.4142L24 53.4142L18.5858 48H8C3.58172 48 0 44.4183 0 40V8C0 3.58172 3.58172 0 8 0Z" fill="#005EB8"/>
  
  <!-- House icon -->
  <g clipPath="url(#clip0_29422_2735)">
    <path
      d="M33.3333 19.6131L26.2226 14.0824C25.5986 13.5969 24.8306 13.3334 24.04 13.3334C23.2494 13.3334 22.4813 13.5969 21.8573 14.0824L14.7453 19.6131C14.3179 19.9454 13.9721 20.3711 13.7343 20.8575C13.4965 21.344 13.373 21.8783 13.3733 22.4197V32.0197C13.3733 32.727 13.6542 33.4053 14.1543 33.9054C14.6544 34.4054 15.3327 34.6864 16.04 34.6864H32.04C32.7472 34.6864 33.4255 34.4054 33.9256 33.9054C34.4257 33.4053 34.7066 32.727 34.7066 32.0197V22.4197C34.7066 21.3224 34.2 20.2864 33.3333 19.6131Z"
      fill="white"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3334 28C26.3867 29.7773 21.6107 29.7773 18.6667 28"
      stroke="#005EB8"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
  
  <!-- Clip path for house icon -->
  <defs>
    <clipPath id="clip0_29422_2735">
      <rect width="32" height="32" fill="white" transform="translate(8 8)" />
    </clipPath>
  </defs>
</svg>
`;

// Encode the SVG to a data URL
const encodedSvg = encodeURIComponent(svgIcon)
  .replace(/'/g, '%27')
  .replace(/"/g, '%22');

const dataUrl = `data:image/svg+xml;charset=UTF-8,${encodedSvg}`;

const HomeDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true); // Define 'loading' state
  const [streetViewImage, setStreetViewImage] = useState(null);
  const [terms, setTerms] = useState(null);
  const { propertyDetail, isLoading, hasError } = useGetPropertyDetail(id);
  const { propertyInfo, schools } = propertyDetail || {};
  const isSchools =
    schools &&
    Object.values(schools).filter((school) => school.length > 0).length > 0;

  const [showMoreSchools, setShowMoreSchools] = useState({
    preschool: false,
    elementary: false,
    middle: false,
    high: false,
  });

  // Fetch terms and street view image
  const fetchData = useCallback(async () => {
    if (!propertyInfo) return;

    try {
      // Fetch terms
      const termsResponse = await fetch(api.buyers.getTermsByListingId(id));
      if (!termsResponse.ok) {
        throw new Error('Network response was not ok');
      }
      const termsData = await termsResponse.json();
      setTerms(termsData);

      // Fetch street view image
      const { address, city, state } = propertyInfo.address;
      const fullAddress = `${address}, ${city}, ${state}`;
      const image = await getStreetViewImage({ address: fullAddress });
      setStreetViewImage(image);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set 'loading' to false after data fetch
    }
  }, [id, propertyInfo]);

  useEffect(() => {
    if (!isLoading && propertyInfo) {
      fetchData();
    }
  }, [isLoading, propertyInfo, fetchData]);

  // Handle navigation back
  const handleClickBack = () => {
    navigate(-1);
  };

  // Handle "Lend" button click
  const handleLendClick = () => {
    navigate(`/application/${id}`, {
      state: {
        propertyDetails: {
          address: propertyInfo.address.address,
        },
        loanTerms: terms,
      },
    });
  };

  const handleToggleViewMore = (type) => {
    setShowMoreSchools((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const renderSchools = (type) => {
    if (!schools[type] || schools[type].length === 0) return null;
    const schoolsToShow = schools[type].slice(
      0,
      showMoreSchools[type] ? schools[type].length : 3
    ); // Show all if 'showMore' is true

    return (
      <div className={styles.schoolTypeContainer}>
        <BaseTypography
          align="left"
          className={styles.infoTitle}
          style={{ color: 'black' }}
        >
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </BaseTypography>
        {schoolsToShow.map((school, index) => (
          <BaseTypography
            key={index}
            align="left"
            className={styles.schoolName}
          >
            {school}
          </BaseTypography>
        ))}
        {schools[type].length > 3 && (
          <Button type="link" onClick={() => handleToggleViewMore(type)}>
            {showMoreSchools[type] ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </div>
    );
  };

  // Show loading skeleton while data is being fetched
  if (loading || isLoading) {
    return <Skeleton active />;
  }

  // Show error message if there's an error fetching property details
  if (hasError) {
    return <div>Error fetching property details.</div>;
  }

  const agent =
    propertyDetail?.mlsHistory && propertyDetail.mlsHistory[0]?.agentName;

  // Ensure position is correctly formatted
  const position =
    propertyDetail.position &&
    typeof propertyDetail.position.lat === 'number' &&
    typeof propertyDetail.position.lng === 'number'
      ? propertyDetail.position
      : defaultCenter;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/* Navigation Back Button */}
        <div className={styles.navigation}>
          <Button
            onClick={handleClickBack}
            size="large"
            type="link"
            style={{ padding: 0 }}
            icon={<LeftOutlined />}
          >
            Back
          </Button>
        </div>

        {/* Title with Address */}
        <Row align="top" className={styles.titleContainer}>
          <Col xs={24} lg={12}>
            <BaseTypography
              className={styles.listingName}
              fontSize={isMobile ? '32px' : '40px'}
              align="left"
            >
              {/* Access the 'name' property of 'neighborhood' */}
              {propertyDetail.neighborhood?.name || propertyDetail.propertyType}
            </BaseTypography>
            <BaseTypography
              className={styles.listingAddress}
              fontSize={isMobile ? '16px' : '20px'}
              align="left"
              color={COLORS.$mainDark}
            >
              {propertyInfo.address.address}
            </BaseTypography>
          </Col>
          <Col xs={24} lg={12}>
            <ButtonsGroup />
          </Col>
        </Row>

        {/* Image Section */}
        <ListingGallery streetViewImage={streetViewImage} />

        {/* Specifications, Agent Info, and Lend Button */}
        <Row className={styles.cardContainer} gutter={20}>
          <Col xs={24} lg={16}>
            <Specifications
              id={propertyDetail.id}
              propertyType={propertyDetail.propertyType}
              price={formatNumberToDollars(propertyDetail.current_price)}
              neighborhood={propertyDetail.neighborhood?.name}
            />
            {isMobile && <DescriptionDetails />}

            {/* General Information Card */}
            <Card style={{ marginBottom: '20px' }} title="General">
              <div className={styles.infoBlock}>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    HOA
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    None
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Acres
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {propertyDetail.acres}
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Type
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {propertyDetail.lotInfo.landUse}
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Laundry
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    In Unit
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Cooling
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {propertyInfo.airConditioningType}
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Heating
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {propertyInfo.heatingType}
                  </BaseTypography>
                </div>
              </div>

              <div className={styles.infoBlock}>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    City
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {propertyInfo.address.city}
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Year Built
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {propertyInfo.yearBuilt ?? 'N/A'}
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Size
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {`${propertyInfo.buildingSquareFeet} sqft`}
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Lot Size
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {`${propertyInfo.lotSquareFeet} sqft`}
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Parking Area
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {propertyInfo.parkingArea ? 'Yes' : 'No'}
                  </BaseTypography>
                </div>
                <div className={styles.info}>
                  <BaseTypography
                    align="left"
                    className={styles.infoTitle}
                  >
                    Garage Size
                  </BaseTypography>
                  <BaseTypography
                    align="right"
                    className={styles.infoValue}
                  >
                    {`${propertyInfo.garageSquareFeet ?? 0} sqft`}
                  </BaseTypography>
                </div>
              </div>
            </Card>

            {/* Schools Information */}
            {isSchools && (
              <Card title="Schools">
                <Row gutter={[20, 20]}>
                  <Col xs={24} sm={12}>
                    {renderSchools('preschool')}
                    {renderSchools('elementary')}
                  </Col>
                  <Col xs={24} sm={12}>
                    {renderSchools('middle')}
                    {renderSchools('high')}
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
          <Col xs={24} lg={8}>
            {/* Lending Terms Card */}
            {terms && (
              <Card title="Lending Terms" className={styles.termsCard}>
                <div className={styles.termsContainer}>
                  <BaseTypography
                    align="left"
                    className={styles.termsText}
                  >
                    Lending Amount: {formatNumberToDollars(terms.lendingAmount)}
                  </BaseTypography>
                  <BaseTypography
                    align="left"
                    className={styles.termsText}
                  >
                    Down Payment: {formatNumberToDollars(terms.downPayment)}
                  </BaseTypography>
                  <BaseTypography
                    align="left"
                    className={styles.termsText}
                  >
                    Interest Rate: {terms.interestRate}%
                  </BaseTypography>
                  <BaseTypography
                    align="left"
                    className={styles.termsText}
                  >
                    Loan Length: {terms.loanLength} years
                  </BaseTypography>
                  <BaseTypography
                    align="left"
                    className={styles.termsText}
                  >
                    Payment Frequency: {terms.paymentFrequency}
                  </BaseTypography>
                  <BaseTypography
                    align="left"
                    className={styles.termsText}
                  >
                    Late Payment Penalties: {terms.latePaymentPenalties}
                  </BaseTypography>
                </div>
              </Card>
            )}

            {/* Invest/Lend Section */}
            <div className={styles.investContainer}>
              <BaseTypography
                className={styles.listPrice}
                align="left"
                fontSize="14px"
              >
                List Price
              </BaseTypography>
              <span className={styles.listPriceValue}>
                {formatNumberToDollars(propertyDetail.mlsListingPrice)}
              </span>

              <Button
                icon={
                  <Icon className={styles.lendIcon} component={LendSvg} />
                }
                className={styles.lendButton}
                type="primary"
                size="large"
                onClick={handleLendClick}
              >
                Request this property
              </Button>
            </div>
          </Col>
        </Row>

        {/* Map Section */}
        <Card title="Map">
          <div className={styles.mapContainer}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={position}
              zoom={13}
            >
              <Marker
                position={position}
                icon={{
                  url: dataUrl,
                  scaledSize: new window.google.maps.Size(30, 30), // Adjust size as needed
                }}
              />
            </GoogleMap>
          </div>
          <Button
            onClick={handleClickBack}
            size="large"
            type="link"
            style={{ padding: 0, marginTop: '10px' }}
            icon={<LeftOutlined />}
          >
            See more listings in {propertyInfo.address.city}
          </Button>
        </Card>
        <Footer />
      </div>
    </div>
  );
};

export default HomeDetails;
