export const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumberToDollarsAbbreviated = (number) => {
  if (number === null || number === undefined) return 'N/A';
  const absNumber = Math.abs(number);

  if (absNumber >= 1e6) {
    // Format numbers >= 1,000,000
    return `$${(number / 1e6).toFixed(1)}M`;
  } else if (absNumber >= 1e3) {
    // Format numbers >= 1,000
    return `$${(number / 1e3).toFixed(0)}k`;
  } else {
    // Format smaller numbers
    return `$${number}`;
  }
}
