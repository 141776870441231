// LegalInformation.styled.js
import styled from 'styled-components';
import { Descriptions } from 'antd';

export const Container = styled.div`
  margin-top: 20px;

  .ant-descriptions-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .ant-descriptions-item-label {
    font-weight: 500;
    background-color: #fafafa;
    padding: 8px;
  }

  .ant-descriptions-item-content {
    padding: 8px;
  }
`;
