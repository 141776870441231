// OwnerDetails.js
import React from 'react';
import {
  Container,
  Title,
  TableWrapper,
  TableInner,
  TableContent,
  InfoGrid,
  InfoItem,
  Label,
  Value
} from './styled';

const OwnerDetails = ({ ownerInfo }) => {
  const details = [
    {
      label: "Owner Name",
      value: ownerInfo.owner1FullName || 'N/A'
    },
    {
      label: "Company Name",
      value: ownerInfo.companyName || 'N/A'
    },
    {
      label: "Ownership Length",
      value: ownerInfo.ownershipLength
        ? `${ownerInfo.ownershipLength} years`
        : 'N/A'
    },
    {
      label: "Owner Occupied",
      value: ownerInfo.ownerOccupied ? 'Yes' : 'No'
    },
    {
      label: "Absentee Owner",
      value: ownerInfo.absenteeOwner ? 'Yes' : 'No'
    },
    {
      label: "Mail Address",
      value: ownerInfo.mailAddress
        ? ownerInfo.mailAddress.address
        : 'N/A'
    }
  ];

  return (
    <Container>
      <Title>Owner Information</Title>
      <TableWrapper>
        <TableInner>
          <TableContent>
            <InfoGrid>
              {details.map((item, index) => (
                <InfoItem 
                  key={item.label}
                  isEven={index % 2 === 0}
                  isLastRow={index >= details.length - 2}
                >
                  <Label>{item.label}</Label>
                  <Value>{item.value}</Value>
                </InfoItem>
              ))}
            </InfoGrid>
          </TableContent>
        </TableInner>
      </TableWrapper>
    </Container>
  );
};

export default OwnerDetails;