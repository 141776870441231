// src/pages/MarketSearch/MarketSearchMap/MarketSearchMap.jsx

import React, {
  useReducer,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { GoogleMap, MarkerClusterer } from '@react-google-maps/api';
import styles from './MarketSearchMap.module.scss';
import reducer from '../../../reducers/UndoRedoReducer';
import useMarkers from '../../../hooks/useMarkers';
import Markers from './Markers';
import { formatNumberToDollars } from '../../../utils/numberFormat';
import mapStyles from '../../../utils/mapStyles';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const mapOptions = {
  disableDefaultUI: false,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  styles: mapStyles,
  clickableIcons: false,
  gestureHandling: 'greedy', // Makes it easier to pan/zoom on desktop
  minZoom: 3,
  maxZoom: 20,
  restriction: {
    latLngBounds: {
      north: 50, // Roughly northern US border
      south: 24, // Roughly southern US border
      west: -125, // Roughly western US border
      east: -66, // Roughly eastern US border
    },
    strictBounds: true,
  },
};

const MarketSearchMap = ({
  properties = [],
  selectedProperty,
  setSelectedProperty,
  initialCenter, // Changed from 'params' to 'initialCenter'
  onMapCenterChanged,
}) => {
  const mapRef = useRef(null);
  const hasFitBounds = useRef(false);
  const hasUserInteracted = useRef(false);
  const [state, dispatch] = useReducer(reducer, {
    past: [],
    now: [],
    future: [],
  });

  // Prepare marker data
  const markerParams = useMemo(
    () => ({
      points: properties.map((property) => ({
        id: property.id,
        position: property.position,
        value: formatNumberToDollars(property.current_price),
        property,
      })),
      polygonPath: state.now.length
        ? state.now[0].snapshot.path.map((coord) => ({
            lat: coord.lat(),
            lng: coord.lng(),
          }))
        : [],
      geometry: null,
      polygon: state.now.length ? state.now[0].geometry : false,
      state,
    }),
    [properties, state]
  );

  const { markers } = useMarkers(markerParams);

  // State to accumulate all markers without duplicates
  const [allMarkers, setAllMarkers] = useState([]);

  // Effect to accumulate markers without duplicates
  useEffect(() => {
    setAllMarkers((prevMarkers) => {
      const existingIds = new Set(prevMarkers.map((marker) => marker.id));
      const newMarkers = markers.filter((marker) => !existingIds.has(marker.id));
      return [...prevMarkers, ...newMarkers];
    });
  }, [markers]);

  // Fit bounds on initial property load
  useEffect(() => {
    if (properties.length > 0 && mapRef.current && !hasFitBounds.current) {
      const bounds = new window.google.maps.LatLngBounds();
      properties.forEach((property) => {
        if (
          property.position &&
          typeof property.position.lat === 'number' &&
          typeof property.position.lng === 'number'
        ) {
          bounds.extend(property.position);
        }
      });

      mapRef.current.fitBounds(bounds);
      hasFitBounds.current = true;

      // Set a reasonable zoom level if the zoom is too high
      window.google.maps.event.addListenerOnce(mapRef.current, 'idle', () => {
        if (mapRef.current.getZoom() > 15) {
          mapRef.current.setZoom(15);
        }
      });
    }
  }, [properties]);

  const onMapLoad = useCallback(
    (map) => {
      mapRef.current = map;

      // Set initial center
      map.setCenter({ lat: initialCenter.latitude, lng: initialCenter.longitude });

      // Add event listeners to detect user interaction
      map.addListener('dragend', () => {
        hasUserInteracted.current = true;
      });

      map.addListener('zoom_changed', () => {
        hasUserInteracted.current = true;
      });
    },
    [initialCenter]
  );

  // Handle map idle event to get new center coordinates
  const handleMapIdle = useCallback(() => {
    if (hasUserInteracted.current && mapRef.current) {
      const center = mapRef.current.getCenter();
      const centerCoords = {
        lat: center.lat(),
        lng: center.lng(),
      };

      onMapCenterChanged(centerCoords);
      hasUserInteracted.current = false;
    }
  }, [onMapCenterChanged]);

  const handleMarkerClick = useCallback(
    (property) => {
      setSelectedProperty((prevSelected) =>
        prevSelected?.id === property.id ? null : property
      );
    },
    [setSelectedProperty]
  );

  const handleMapClick = useCallback(() => {
    setSelectedProperty(null);
  }, [setSelectedProperty]);

  return (
    <div className={styles.mapContainer}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        // Removed the 'center' prop to prevent re-centering on updates
        zoom={13}
        options={mapOptions}
        onLoad={onMapLoad}
        onClick={handleMapClick}
        onIdle={handleMapIdle}
      >
        <MarkerClusterer
          options={{
            gridSize: 60,
            minimumClusterSize: 7,
          }}
        >
          {(clusterer) =>
            allMarkers.map((marker) => (
              <Markers
                key={marker.id}
                point={marker}
                onClick={() => handleMarkerClick(marker.property)}
                isSelected={selectedProperty?.id === marker.property.id}
                clusterer={clusterer}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
};

export default MarketSearchMap;
